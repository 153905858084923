<section class="mt-2">
    <!-- <div class="font-medium text-base tracking-[-0.32px] leading-[22px] flex items-center justify-between mb-1">
        @if(type=="transfer"){
            Transfer Call
        }@else if(type=="merge"){
            Merge Call
        }
        <mat-icon svgIcon="heroicons_mini:x-mark" class="cursor-pointer" (click)="onClose()"></mat-icon>
    </div> -->
    <div class="relative mb-1.5 mx-auto h-10 border border-gray-300 rounded-md shadow-sm ">
        <input #searchInput name="searchText" class="py-2 pl-11 text-[16px] font-light focus:outline-none" 
        placeholder="Search agent" [formControl]="searchControl" id="searchField">
        <div matRipple class="search-icon text-xl max-h-10 absolute inset-y-0  flex items-center px-2 text-gray-70 focus:outline-none focus:border-blue-500">
          <img src="../../../../assets/icons/search-normal.svg" class="w-5"/>
        </div>
        <button mat-icon-button *ngIf="searchControl.value" class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-70 focus:outline-none" (click)="clearSearch()">
            <mat-icon svgIcon="heroicons_mini:x-mark"></mat-icon>
        </button>
    </div>
    <div class="flex justify-between">
        <div class="w-full agents-list" #agents (scroll)="onAgentsScroll(agents)" [ngClass]="isLoading ? 'overflow-hidden pr-2' : 'overflow-y-auto overflow-x-hidden'">
            @if(rows?.length > 0 && !isLoading){
            <ng-container *ngFor="let row of rows; trackBy: trackByFn; let last = last; let i = index">
                <!-- (click)="callNumber"  -->
                <a *ngIf="userId !== row.id" #rowElement class="flex flex-col items-center justify-between animate__animated animate__fadeIn" 
                    [ngClass]="{'border-b':!last, 'bg-[#1065B41A]':row.id === expandedRowId,'hover:bg-gray-50 item':row.id != expandedRowId, 'cursor-pointer':type=='merge'}"
                    (mouseenter)="onMouseEnter(row,rowElement,last)" (mouseleave)="onMouseLeave()" (click)="mergeCall(row)">
                    <div class="w-full flex">
                        <div class="flex items-center w-full py-2">
                            <div class="flex-0 items-center justify-center w-11 h-11 border p-[3px] rounded-full ml-1.5">
                                <ng-container *ngIf="row?.primary_image">
                                    <img class="w-full h-full rounded-full object-cover" [src]="fileUrl+row.primary_image"
                                        alt="Contact avatar" />
                                </ng-container>
                                <ng-container *ngIf="!row?.primary_image">
                                    <div class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase
                                    bg-gray-200 text-gray-600">
                                        @if(row.first_name){
                                        {{row.first_name+' '+(row.last_name?row.last_name:'') | shortName}}
                                        }@else {
                                        UN
                                        }
                                    </div>
                                </ng-container>
                            </div>
                            <div class="flex flex-col flex-auto min-w-0 ml-2">
                                <div class="flex flex-row text-center items-center">
                                    <div class="font-medium text-base leading-[22px] truncate tracking-[-0.32px] max-w-[13.5rem]">
                                        {{row.first_name+' '+(row.last_name?row.last_name:'')}}
                                    </div>
                                </div>
                                <div class="flex flex-row">
                                    <div *ngIf="row.sip_username" class="text-base font-light leading-[22px] truncate">{{row.sip_username}}</div>
                                    <div *ngIf="!row.sip_username"
                                        class="text-base font-light leading-[22px] truncate tracking-[-0.32px]">No phone number
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex items-center justify-center rounded-full mr-2 select-none">
                            @if(type=="transfer"){
                                <img src="../../../../../assets/icons/call-center/repeat-circle-transfer.svg" alt="">
                            }@else if(type=="merge"){
                                <img src="../../../../../assets/icons/call-center/repeat-circle-merge.svg" alt="">
                            }
                        </div>
                    </div>

                    <!-- Expanded transfer options Content -->
                    <div *ngIf="expandedRowId === row.id" class="px-2.5 py-3 pt-0 flex flex-col w-full cursor-default select-none"
                        [@slideInOut]="'in'">
                        <p class="text-base font-medium tracking-[-0.32px] leading-[22px]">Choose a transfer option below, if no-reply</p>
                        <div class="flex justify-between text-sm font-medium mt-1.5">
                            <button class="bg-[#0ABC7C] rounded-md px-3 py-2 text-white flex items-center justify-center cursor-pointer"
                            (click)="transferCall(transferType.CALL,row)">
                                <i class="icon-call-incoming text-[20px] flex mr-1"></i>
                                Return to Call
                            </button>
                            <button class="bg-[#1065B4] rounded-md px-3 py-2 text-white flex items-center justify-center cursor-pointer"
                            (click)="transferCall(transferType.VOICEMAIL,row)">
                                <i class="icon-voice-square text-[20px] flex mr-1"></i>
                                Send to Voicemail
                            </button>
                        </div>
                    </div>

                    <!-- * this div is to find the index of element enterd to viewport -->
                    <div appVisibilityCheck (visibilityChange)="onVisibilityChange($event, i)"></div>
                </a>
            </ng-container>
            <ng-container *ngIf="currentCount < totalCount">
                <div class="flex pt-1 pb-2 px-1.5 mt-[5px] w-full">
                    <div class="self-center">
                        <ngx-skeleton-loader count="1" appearance="circle" class="flex"
                            [theme]="{height:'44px', width:'44px','background':'#e1e1e1','margin':'0px'}" />
                    </div>
                    <div class="w-full ml-2">
                        <div>
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{'background':'#e1e1e1',height:'15px',width:'75%','margin-bottom': '0px'}" />
                        </div>
                        <div>
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{'background':'#e1e1e1',height:'15px',width:'50%','margin-bottom': '0px'}" />
                        </div>
                    </div>
                </div>
            </ng-container>

            }@else if (isLoading){
            <!-- skeleton template -->
            <ng-container *ngFor="let repeat of [1, 2, 3, 4, 5, 6]">
                <div class="flex pt-1 pb-2 px-1.5 mt-[5px] border-b w-full">
                    <div class="self-center">
                        <ngx-skeleton-loader count="1" appearance="circle" class="flex"
                            [theme]="{height:'44px', width:'44px','background':'#e1e1e1','margin':'0px'}" />
                    </div>
                    <div class="w-full ml-2">
                        <div>
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{'background':'#e1e1e1',height:'15px',width:'75%','margin-bottom': '0px'}" />
                        </div>
                        <div>
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{'background':'#e1e1e1',height:'15px',width:'50%','margin-bottom': '0px'}" />
                        </div>
                    </div>
                </div>
            </ng-container>
            }
            @else{
            <div class="w-full h-full flex items-center justify-center">
                <p class="text-base text-slate-500">No agents to display.</p>
            </div>
            }
        </div>
        <div class="alphabet-bar botomSide" #alphabetBar (scroll)="onAlphabetScroll(alphabetBar)" *ngIf="((!selectedLettertobeFiltered && rows.length>10) || selectedLettertobeFiltered) && !searchControl.value && !isLoading">
            <ng-container *ngFor="let char of alphabets; let first=first; let last=last">
                <div class="letter" [ngClass]="{'active':selectedLetter==char,
                                                'pt-1':first, 'pb-1':last}"
                    (click)="alphabetOnClick(char)" [attr.data-letter]="char">{{char}}</div>
            </ng-container>
        </div>
    </div>
</section>
