<div class="fixed panel-container z-48 bottom-0">
 <div class="flex flex-col w-full h-full transition-transform duration-400 ease-drawer bg-card shadow"
        [ngClass]="{'-translate-x-full shadow': opened, 'translate-x-0': !opened}">
        <div class="flex h-full">
            <div class="calllist-container" [ngClass]="!callcenterShared.activeCallerSession
             && this.callcenterShared.showDialer == true &&
             this.callcenterShared.callQ?.length>0? 'expand container-box' : 'shrink'">
                <call-list 
                *ngIf="!callcenterShared.activeCallerSession && this.callcenterShared.showDialer == true &&
                this.callcenterShared.callQ?.length>0"
                (pickCall) = "onPickCall($event)"  (rejectCall) = "onRejectCall($event)" (holdCall) = "onHoldCall($event)"
                (endCall) = "onEndCall($event)"></call-list>
            </div>
            <div #scrollableContainer class="callcenter-container call-section-container">
                <div class="mt-1 top-container">
                    <img class="w-4 h-5 flex ml-auto cursor-pointer"  (click) = "closePanel()"
                    src="../../../../../assets/icons/call-center/close.svg" alt="Mute">
                    <ng-container *ngIf="opened">
                        <call-center (scrollToTopEvent)="scrollToTop()"></call-center>
                    </ng-container> 
                </div>
            </div> 
        </div>
</div>
</div>

