import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from 'app/app.component';
import { appConfig } from 'app/app.config';
import { provideServiceWorker } from '@angular/service-worker';
import { environment } from 'environments/environment';

bootstrapApplication(AppComponent, {
    providers: [
        ...appConfig.providers,

        // provideServiceWorker('ngsw-worker.js', {
        //     enabled: true,
        //     registrationStrategy: 'registerWhenStable:30000',
        //     // registrationStrategy: 'registerImmediately', 
        // }),
        // provideServiceWorker('firebase-messaging-sw.js', {
        //     enabled: true,
        //     registrationStrategy: 'registerWhenStable:30000',
        //     // registrationStrategy: 'registerImmediately', 
        // }),
    ],
}).catch(err => console.error(err));

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then(registration => {
            console.log('Custom Service Worker registered with scope:', registration.scope);
        })
        .catch(error => {
            console.error('Custom Service Worker registration failed:', error);
        });

    // navigator.serviceWorker.register('/ngsw-worker.js', { scope: '/pwa' })
    //     .then(registration => {
    //         console.log('Custom Service Worker registered with scope:', registration.scope);
    //     })
    //     .catch(error => {
    //         console.error('Custom Service Worker registration failed:', error);
    //     });
    
    
}
    
