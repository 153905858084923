import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataFilterRequest, DataFilterResponse, GFilterParam, GSortParam } from 'app/core/models/grid-filter.models';
import { SubSink } from 'subsink';
import { CompanyService } from '../../services/company.service';
import { CustomerContact } from '../../models/companies.models';
import { ShortNamePipe } from 'app/shared/pipes/short-name.pipe';
import { PhoneNumberPipe } from 'app/shared/pipes/phone-number.pipe';
import { catchError, debounceTime, distinctUntilChanged, from, map, Observable, of, Subject, switchMap } from 'rxjs';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { VisibilityCheckDirective } from 'app/shared/directives/visibility-check.directive';
import { ToastrService } from 'ngx-toastr';
import { ToolTipDirective } from 'app/shared/directives/tool-tip.directive';
import { environment } from 'environments/environment';
import { error } from 'console';
import { AppConstants } from 'app/core/constants/app.constants';
import { InitiateCallDirective } from 'app/shared/directives/initiate-call.directive';

@Component({
  selector: 'contact-search',
  standalone: true,
  imports: [CommonModule, ShortNamePipe, PhoneNumberPipe, NgxSkeletonLoaderModule, VisibilityCheckDirective, ToolTipDirective,
    InitiateCallDirective
  ],
  templateUrl: './contact-search.component.html',
  styleUrl: './contact-search.component.scss'
})
export class ContactSearchComponent implements OnDestroy {

  @Output() selectedTabOnChange: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('contacts') private contactsBlock: ElementRef;

  @ViewChild('alphabetBar') alphabetBar: ElementRef;

  @ViewChild(InitiateCallDirective) private initiateCallDirective: InitiateCallDirective;

  private sort: GSortParam[] = [{ colname: 'first_name', direction: 'asc' }];

  public searchFilter: GFilterParam[];

  private subs: SubSink = new SubSink();

  public page = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

  rows: CustomerContact[] = [];

  @Input() contactId: number;

  @Input() searchControl: any;

  @Output() addForCall = new EventEmitter<any>();

  public selectedContact: CustomerContact;

  public alphabets: string[] = ['#', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N',
    'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

  public selectedLetter: string = '';

  public selectedLettertobeFiltered: string = '';

  isLoading: boolean = false;

  bottomReached: boolean = false;

  currentCount: number = 0;

  totalCount: number = 0;

  isLoadingMore: boolean = false;

  letterSearch: boolean = false;

  fileUrl: string = environment.imgURL;

  agentSipUri: string;

  private initialLoad: boolean = false;

  public storeData$ = new Subject<any>();

  constructor(private tenantCustService: CompanyService,
    private renderer: Renderer2,
    private _cdr: ChangeDetectorRef,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.initialLoad = true
    this.agentSipUri = localStorage.getItem(AppConstants.SIP_URI)
    this.loadData()
    this.getFilteredDataFromLocalDB();

    this.subs.add( 
      this.searchControl.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged()).subscribe(value=>{
          this.onSearchInput(value)
        }));
  }
  getFilteredDataFromLocalDB() {
    this.subs.sink = this.storeData$.subscribe((res) => {
  
      if(this.initialLoad)
      {
        this.rows = [];
      }
      this.rows = [...this.rows, ...res.data?.result];
      console.log('rows',this.rows)

      this.isLoading = false;
      this.isLoadingMore = false;
      this.bottomReached = false;
      console.log('letterSearch', this.letterSearch);
      (this.letterSearch == true) ? 
      this.currentCount = res.data?.count : this.currentCount += res.data?.count;
      this.totalCount = res.data?.total_count;
      console.log('loadMore', this.isLoading);
      console.log('totalcountonload', this.totalCount);
      console.log('currentcountonload', this.currentCount);
      this._cdr.markForCheck();
   
    }

  )
  }
  fetchFilteredDataFromAPI(letter: string = null, filters: GFilterParam[] = [], offset: number = 0,apicall:boolean = true) {
    let option: any = {
      filters: filters,
      sort: this.sort,
      base_filters: []
    };
    if (letter && letter != "#") {
      filters.push(
        {conditions:[{ displayName: "First Name", colname: "first_name", condition: "startswith", value: letter, operator: "OR" }],   "operator": "AND"
      },
      );
    }
    this.subs.sink = this.tenantCustService.fetchCustomeContactsFiltered_LocalDB(this.storeData$,option,this.page.limit,offset)
  }

  loadData(loadMore: boolean = false, letter: string = null, filters: GFilterParam[] = [], offset: number = 0,isNewUpdate:boolean=false) {


    if (loadMore) {
      this.initialLoad = false
      this.isLoading = false;
    } else {
      this.isLoading = true;
      this.initialLoad = true;
      this.rows = []
    }
    if(letter)
    {
      this.letterSearch = true
    }
    else
    {
      this.letterSearch = false
    }

    this.isLoadingMore = true;

    this.fetchFilteredDataFromAPI(this.selectedLettertobeFiltered, filters, offset)
  }




  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  alphabetOnClick(alphabet) {
    this.selectedLettertobeFiltered = alphabet;
    this.loadData(false, alphabet);
    this.scrollToTop();
  }

  scrollToTop() {
    console.log('scrollToTop');
    const element = this.contactsBlock.nativeElement;
    if (element.scrollTop !== 0) {
      element.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  scrollToDown() {
    const element = this.contactsBlock.nativeElement;
    element.scrollTo({ top: element.scrollHeight, behavior: 'smooth' });
  }

 onSearchInput(val) {
    console.log('searchval',val)
    this.selectedTabOnChange.emit("Contact")
    this.isLoading = true;
    this.isLoadingMore = true;
    this.selectedLetter = ''
    this.currentCount = 0;
    this.totalCount = 0;
    if (val) {
      this.rows = []
      this.letterSearch = true

      this.searchFilter = [{
        conditions: [
          { displayName: "First Name", colname: "first_name", condition: "contains", value: val, operator: "OR" },
          { displayName: "Last Name", colname: "last_name", condition: "contains", value: val, operator: "OR" },
          { displayName: "Phone", colname: "phone", condition: "contains", value: val, operator: "OR" },
          { displayName: "Mobile", colname: "mobile", condition: "contains", value: val, operator: "OR" },
      ], operator : "AND"}]
      return  this.fetchFilteredDataFromAPI(null, this.searchFilter, 0);
    }
    else {
      this.searchFilter = []
      this.rows = []
      this.initialLoad = true
      return this.fetchFilteredDataFromAPI(null, [], 0);
    }
  }

  callNumber(number: string) {

    if (number?.length > 0) {
      this.addForCall.emit(number);
    }
    else {
      this.toastr.error("Sorry ! No Phone number is associated with this contact.")
    }
  }

  onContactScroll(historyBlock: HTMLElement): void {
    const scrollTop = historyBlock.scrollTop;
    const scrollHeight = historyBlock.scrollHeight;
    const offsetHeight = historyBlock.offsetHeight;
    const isScrolledToBottom = Math.floor(scrollTop + offsetHeight) >= scrollHeight - 1;

    console.log('isScrolledToBottom',isScrolledToBottom)
    console.log('bottomReached',this.bottomReached)

    if (isScrolledToBottom && !this.bottomReached) {
      this.bottomReached = true;
      if (!this.isLoading && (this.currentCount < this.totalCount)) {
        this.isLoading = true;
        let offset = this.currentCount;
        // API to get more contacts
        let filters = [];
        if (this.searchFilter) {
          filters = this.searchFilter;
        }
        this.loadData(true, this.selectedLettertobeFiltered, filters, offset);
      }
      this._cdr.markForCheck();
    } else {
      this.bottomReached = false;
    }
  }

  //To assign selectedLetter according to data in viewport
  onVisibilityChange(isVisible: boolean, index: number) {

    if (isVisible) {
      this.updateSelectedLetter(index);
     console.log('isVisible - ', isVisible, 'index - ', index);
    }
  }

  updateSelectedLetter(visibleIndex: number) {
    if (this.rows[visibleIndex]) {
      const firstName = this.rows[visibleIndex].first_name;
      const letter = firstName.charAt(0).toUpperCase();
      this.selectedLetter = letter >= 'A' && letter <= 'Z' ? letter : '#';
    }
    if (this.alphabetBar && this.selectedLetter) {
      const letterElement = this.alphabetBar.nativeElement.querySelector(`.letter[data-letter="${this.selectedLetter}"]`);
      if (letterElement) {
        const alphabetBarRect = this.alphabetBar.nativeElement.getBoundingClientRect();
        const letterRect = letterElement.getBoundingClientRect();
        const scrollOffset = letterRect.top - alphabetBarRect.top - (alphabetBarRect.height - letterRect.height) / 2;
        this.alphabetBar.nativeElement.scrollTo({
          top: this.alphabetBar.nativeElement.scrollTop + scrollOffset,
          behavior: 'smooth'
        });
      }
    }
  }

  onAlphabetScroll(alphabetBar: HTMLElement): void {
    const scrollTop = alphabetBar.scrollTop;
    const scrollHeight = alphabetBar.scrollHeight;
    const offsetHeight = alphabetBar.offsetHeight;
    const isScrolledToBottom = scrollTop + offsetHeight >= scrollHeight;

    if (isScrolledToBottom) {
      this.renderer.addClass(alphabetBar, 'topSide');
      this.renderer.removeClass(alphabetBar, 'botomSide');
      this.renderer.removeClass(alphabetBar, 'bothSide');

    } else {
      this.renderer.addClass(alphabetBar, 'botomSide');
      this.renderer.addClass(alphabetBar, 'bothSide');
    }

    if (scrollTop > 0 && !isScrolledToBottom) {
      this.renderer.removeClass(alphabetBar, 'botomSide');
    } else {
      this.renderer.removeClass(alphabetBar, 'bothSide');
    }
  }

  onAvatarMouseEnter(){
    this.initiateCallDirective.hide();
  }

  onAvatarMouseLeave(){
      this.initiateCallDirective.show();
  }



  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }
}
