<div class="div">
    <outbound-dialer id="#dialer" (inputChange)="searchInContacts($event)"></outbound-dialer>
</div>
<div class="mt-2">
    <div class="tab-switch-container mb-2">
        <a class="tab-switch grow" [ngClass]="{'active':tabShowType=='History'}" (click) = "tabShowType='History'">Calls History</a>
        <a class="tab-switch grow" [ngClass]="{'active':tabShowType=='Contact'}" (click) = "tabShowType='Contact'">Contacts</a>
    </div>
    <div class="div">
        <div class="relative mx-auto h-10 border border-gray-300 rounded-md shadow-sm">
            <input #searchInput name="searchText" class="py-2 pl-11 font-light focus:outline-none" 
            placeholder="Search contact"  [formControl]="searchControl">
            <div  matRipple class="search-icon text-xl max-h-10 absolute inset-y-0  flex items-center px-2 text-gray-70 focus:outline-none focus:border-blue-500">
              <img src="../../../../assets/icons/search-normal.svg" class="w-5"/>
            </div>
            <button mat-icon-button *ngIf="searchControl.value" class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-70 focus:outline-none" (click)="clearSearch()">
                <mat-icon svgIcon="heroicons_mini:x-mark"></mat-icon>
            </button>
        </div>
        <div class="mt-1">
            <div [hidden]="tabShowType!='History'">
                <recent-call-history (addForCall) = "addForCall($event)"></recent-call-history>
            </div>
            <div [hidden]="tabShowType!='Contact'" #contactSearch>
                <contact-search (addForCall) = "addForCall($event)" [searchControl]="searchControl" (selectedTabOnChange)="tabShowType=$event"></contact-search>
            </div>
        </div>
    </div>
</div>
