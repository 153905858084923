import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as moment from 'moment-timezone';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'timezone-select',
  standalone: true,
  imports: [CommonModule, FormsModule, NgSelectModule],
  templateUrl: './timezone-select.component.html',
  styleUrl: './timezone-select.component.scss'
})
export class TimezoneSelectComponent  {

  @Input() value: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

  @Input() disable: boolean = false;

  @Output() onChange = new EventEmitter<any>();

  options: string[];

  ngOnInit() {
    this.options= moment.tz.names();
  }

  onChangeEvent(event: any): void {
    this.value = event;
    this.onChange.emit(event);
  }
}
 
