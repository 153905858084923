import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneNumberPipe } from 'app/shared/pipes/phone-number.pipe';
import { TranslocoModule } from '@ngneat/transloco';
import { KeyPadComponent } from '../key-pad/key-pad.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ShortNamePipe } from 'app/shared/pipes/short-name.pipe';
import { ToastrService } from 'ngx-toastr';
import { CallCenterSharedService } from '../../services/call-center-shared.service';
import { InitiateChatDirective } from 'app/shared/directives/initiate-chat-direcive';
import { AppEventType, CommonPanelEvents } from 'app/core/enums/common.enum';
import { CommonService } from 'app/core/services/common.service';
import { sidePanelConstants } from 'app/core/constants/app-shared.constants';
import { DialerAgentListComponent } from '../dialer-agent-list/dialer-agent-list.component';
import { SubSink } from 'subsink';
import { MatIconModule } from '@angular/material/icon';
import { environment } from 'environments/environment';
import { SipInboundFunctionService } from '../../services/sip-inbound-function.service';
import { AgentAndContactListComponent } from '../agent-and-contact-list/agent-and-contact-list.component';
import { ProfileDetailsComponent } from 'app/shared/components/profile-details/profile-details.component';
import { MergedCallListResponse } from '../../models/call-center.models';

@Component({
  selector: 'active-call-info',
  standalone: true,
  imports: [CommonModule, PhoneNumberPipe, TranslocoModule, KeyPadComponent, ShortNamePipe, InitiateChatDirective,
     DialerAgentListComponent, MatIconModule, AgentAndContactListComponent, ProfileDetailsComponent],
  templateUrl: './active-call-info.component.html',
  styleUrl: './active-call-info.component.scss',
  animations: [
    trigger('fadeInOut', [
      state('in', style({
        opacity: 1,
        transform: 'translateY(0)'
      })),
      transition('void => *', [
        style({
          opacity: 0,
          transform: 'translateY(-20px)'
        }),
        animate('0.5s ease-out')
      ]),
      transition('* => void', [
        animate('0.5s ease-in', style({
          opacity: 0,
          transform: 'translateY(-20px)'
        }))
      ])
    ])
  ]
})
export class ActiveCallInfoComponent {
    
  @Output() isClosed = new EventEmitter<boolean>();

  @Input() callerSession;

  @Input() audioInputSource;

  @Input() videoSource;

  @Input() show = false;

  @Output() movetoAcceptedCallsQ = new EventEmitter<any>();

  @Output() moveToAutoAccept = new EventEmitter<string>();

  @Output() clearEndedWhileonHoldCall: EventEmitter<boolean> = new EventEmitter<boolean>();

  public declineTone = new Audio();

  @Output() pickCall = new EventEmitter<any>

  @Output() rejectCall = new EventEmitter<any>

  @Output() endCall = new EventEmitter<any>

  @Output() holdCall = new EventEmitter<any>

  @Output() muteCall = new EventEmitter<any>

  public showKeypad : boolean = false;

  private subs:SubSink=new SubSink();

  public fileURL = environment.imgURL;

  activeMergedCalls: MergedCallListResponse[] = [];

  constructor(private _toaster: ToastrService,
    public _callcenterSharedService: CallCenterSharedService,
    private commonService: CommonService,
    public inboundFnService: SipInboundFunctionService
  ) {

  }
  ngOnInit(): void {
    this.suscribeSidepanelEvents()
  }

  suscribeSidepanelEvents(){
    this.subs.sink = this.commonService.on(AppEventType.CommonPanelEvent).subscribe(event => {
      if (event.payload) {

       if (event.payload.EventName == CommonPanelEvents.SaveContact) {
          const saveInfo = event.payload.AdditionalInfo.saveInfo;
          console.log('saveInfo',saveInfo)
        if(this.callerSession.customValues?.callType == 'inbound')
          {
            this.callerSession.callDetails.company_users_name  = `${saveInfo.first_name}${saveInfo.last_name ? ' ' + saveInfo.last_name : ''}`;
          }
        else if(this.callerSession.customValues?.callType == 'outbound')
        {
          this.callerSession.callDetails.users_name =  `${saveInfo.first_name}${saveInfo.last_name ? ' ' + saveInfo.last_name : ''}`;
        }
        this.callerSession.callDetails.users_id = saveInfo.id;
        this.callerSession.callDetails.company_users_image = saveInfo.image;
        this.callerSession.callDetails.company_name = saveInfo.company_name;
        }
      }
    })
  }

  ngAfterViewInit(){
  }
  /**
   * 
   * mute ongoing call
   */

  onMuteCall() {

    
    this.muteCall.emit(this.callerSession)
  }

  /**
   * hold and unhold
   * create new call with music on hold
   * reconnect with new call on unhold
   */ 

  public onHoldCall() {
    this.holdCall.emit(this.callerSession);
    this.inboundFnService.showTransferList = false;
  }

  /**
   * pickup incoming call
   */ 
  onPickkCall() {

    this.pickCall.emit(this.callerSession)


  }

  /**
   * 
   * reject incoming call
   */

  onRejectCall() {

    this.rejectCall.emit(this.callerSession)
  }
/**
 * hangup ongoing call
 */
  onEndCall() {
    console.log("--callersession--",this.callerSession)
    this.endCall.emit(this.callerSession)
   
  }
  formatTime(seconds: number): string {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return mins+'m:'+secs+'s'
  }

  // private pad(value: number): string {
  //   return value < 10 ? '0' + value : value.toString();
  // }
  onMergeClick(){
    this.inboundFnService.showTransferList = false;
    this._callcenterSharedService.showMergeList = !this._callcenterSharedService.showMergeList;
    
  }
  transfer(){
    this._callcenterSharedService.showMergeList = false;
    this.inboundFnService.showTransferList = !this.inboundFnService.showTransferList;
  }

  onShowKeypad(){
    this.showKeypad = !this.showKeypad
  }

  showMoreInfo(data: any) {
    const sidePanelConstants:sidePanelConstants ={EventName:CommonPanelEvents.OpenContactView}
    if(data.users_id){
      sidePanelConstants.AdditionalInfo = {id:data.users_id, phone: this.getPhoneNumber()};
    }
    this.commonService.dispatch({type:AppEventType.CommonPanelEvent,payload:sidePanelConstants});
  }

  addContact(phone: string) {
    const sidePanelConstants:sidePanelConstants ={EventName:CommonPanelEvents.OpenContact}
    if(phone){
      sidePanelConstants.AdditionalInfo = {users_number: phone};
    }
    this.commonService.dispatch({type:AppEventType.CommonPanelEvent,payload:sidePanelConstants});
  }
  onCloseKeypad(){
    this.showKeypad = false
  }
  isNullorEmpty(value: string | null): boolean {
    return value === null || value === "" || value === undefined;
  }

  getPhoneNumber(){
    if(this.callerSession?.customValues?.isAgentCall) return null;
    if(this.callerSession?.customValues?.callType == 'inbound'){
      return this.callerSession?.callDetails?.from_number?.length > 6 ? this.callerSession?.callDetails?.from_number : null;
    }else{
      return this.callerSession?.callDetails.users_number || null
    }
  }

  ngOnDestroy(){
    this.subs.unsubscribe();
  }

}
