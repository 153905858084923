import { Injectable } from '@angular/core';
import { RegistererState, SessionState } from 'sip.js';
import { BehaviorSubject, Subscription } from 'rxjs';
import { InboundMergeAgentPayload, InboundMergeCustomerPayload, TransferCallPayload } from '../models/call-center.models';
import { CallCenterService } from './call-center.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { CallCenterSharedService } from './call-center-shared.service';
import { AppEventType, CommonPanelEvents, StatusValueEnum } from 'app/core/enums/common.enum';
import { CallNotificationService } from './call-notification.service';
import { SipConnectionService } from './sip-connection.service';
import _ from 'lodash';
import { FireMessagingService } from 'app/core/services/fire-messaging.service';
import { AppEvent } from 'app/core/models/common.models';
import { CommonService } from 'app/core/services/common.service';
import { sidePanelConstants } from 'app/core/constants/app-shared.constants';
import { AppConstants } from 'app/core/constants/app.constants';
import { BaseService } from 'app/core/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class SipInboundFunctionService extends BaseService{

  public connection: any

  public totalCallCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public declineTone = new Audio();

  public acceptTone = new Audio();

  public callLogOpen: boolean = true;

  public subs: Subscription = new Subscription();

  public iscallcenterEnabled: boolean = true;

  public block_id: number;

  public remoteAudio = new Audio();

  public audioInputSource: string;

  public videoInputSource: any;

  public is_override : boolean = false;

  public showTransferList: boolean = false;

  // public showMergeList: boolean = false;

  constructor(
    private callCenterService: CallCenterService,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private callcenterSharedService: CallCenterSharedService,
    private callNotifyService: CallNotificationService,
    private sipConnectionService:SipConnectionService,
    private _fireMessagingService: FireMessagingService,
    private commonService: CommonService,
  
  ) {  super() }

  inviteCall() {
    this.connection = this.callcenterSharedService.connection
    let tis = this;
 
    this.callcenterSharedService.connection.delegate = {
 
      onInvite(invitation: any): void {
        const callHeaders = invitation.request.headers;
        const callTypeHeader = callHeaders['X-Call-Type']?.[0]?.raw;
        const callStatusHeader = callHeaders['X-Call-Status']?.[0]?.raw;
        const callIdHeader = callHeaders['X-Signalwire-Callsid']?.[0]?.raw;
        const isAgentToAgent = callTypeHeader === 'agent_to_agent_inbound';
      
        if (tis.callcenterSharedService.sipDetails.status === StatusValueEnum.BUSY && 
            !isAgentToAgent && (!callStatusHeader || callStatusHeader !== 'unholded')) {
          console.log('%c Agent is busy, only direct calls are allowed', 'color: orange;');
          return;
        }
      
        if (callIdHeader) {
          // Ignore call if it is unheld on another device without auto-accept verification
          if (callStatusHeader === 'unholded' && !tis.callcenterSharedService.verifyCallIdAutoAccept(callIdHeader)) {
            console.warn("This call has been unheld on another device/session. This session is being ignored to prevent conflict.");
            return;
          }
      
          console.log("%c New call invitation received at " + new Date().toLocaleTimeString(), "color: yellow;");
      
          // Check if the invitation already exists in the call invitations array
          const existingIndex = tis.callcenterSharedService.callInvitations.findIndex(
            (inv) => inv.request.headers['X-Signalwire-Callsid']?.[0]?.raw === callIdHeader
          );
      
          if (existingIndex !== -1) {
            console.log('Invitation with the same callId already exists. Ignored.');
          } else {
            tis.callcenterSharedService.callInvitations.push(invitation);
          }
      
          console.log("%c <<--Invitations in Array-->>", 'color: orange;', tis.callcenterSharedService.callInvitations);
      
          if (callStatusHeader === 'unholded' && tis.callcenterSharedService.verifyCallIdAutoAccept(callIdHeader)) {
            // Handle unhold scenario
            tis.acceptUnholdCall(invitation, callIdHeader);
          } else {
            // Handle direct call if not already in the call queue
            if (!tis.callcenterSharedService.callQ.some(call => call.callDetails?.call_id === callIdHeader)) {
              tis.handleDirectCallInvitation(invitation);
            }
          }
        }else if(invitation.request.headers['X-Conference-Callsid']){
          //Auto accept conference call logic
          const conf_callid = invitation.request.headers['X-Conference-Callsid'][0].raw
          console.log("%c New conference invitation received at "+ conf_callid +' '+ new Date().toLocaleTimeString(), "color: yellow;");

          let existingIndex = tis.callcenterSharedService.callInvitations.findIndex(
            (inv) => inv.request.headers['X-Signalwire-Callsid']?.[0]?.raw === conf_callid
          );
          
          // If invitation with the same callId already exists, ignore it. Else add it to array.
          existingIndex !== -1 
          ? this.callcenterSharedService.callInvitations[existingIndex] = invitation 
          : tis.callcenterSharedService.callInvitations.push(invitation);

          if (invitation.request.headers['X-Call-Status'] &&
            invitation.request.headers['X-Call-Status'][0].raw == 'merged_call' &&
            tis.callcenterSharedService.conferenceCallId == conf_callid) {
              tis.autoAcceptConferenceCall(conf_callid,invitation);
            }
        }
      }
    };
  }

  private acceptUnholdCall(invitation: any, callId: string): void {
    const callerSession = this.callQ.find(x => x.callDetails.call_id === callId);
  
    if (callerSession) {
      callerSession.session = invitation;
      callerSession.session.accept();
      console.log("%c <--Accepted the redirected call-->", 'color: green;');
  
      this.callcenterSharedService.stopCallTimer(callerSession);
      this.listenToSessionStatusChanges(callerSession);
    }
  }

  listenToSessionStatusChanges(callerSession: any) {
  
   // this.callNotifyService.checkAndclearNotifyOnIncoming(callerSession.callDetails.call_id)
    let tis = this; 
    // Handle incoming session state changes.
    const invitation = callerSession.session
    invitation.stateChange.addListener((newState: SessionState) => {

      switch (newState) {

        case SessionState.Initial:
          console.log('Initial');
          break;

        case SessionState.Establishing:
          console.log('Session is establishing.')
          // tis.ringAudio.pause();
          break;

        case SessionState.Established:

        
          tis.callcenterSharedService.handleMic(true);
          tis.callAcceptTone()
          tis.callcenterSharedService.stopRing()
          tis.callcenterSharedService._agentStatus.next({ status: StatusValueEnum.ON_CALL, updateStatus: true })
          tis.remoteAudio.srcObject = invitation.sessionDescriptionHandler['remoteMediaStream'];
          tis.remoteAudio.play();
          if (!invitation.elapsedTime) {
            invitation.elapsedTime = 0
          }
          tis.callcenterSharedService.startCallTimer(callerSession)

          if(callerSession.customValues.isMuted)
            {
              tis.callcenterSharedService.muteCall(callerSession)
            }
            callerSession.customValues.notification_established  = true
            callerSession.customValues.call_answered = true
            if(callerSession.customValues.callConnectTime == null)
              callerSession.customValues.callConnectTime  = new Date().getTime();
            let local_session = _.merge({}, callerSession)
            local_session.session = {};
            local_session.session["state"] = "Established";
            this.callcenterSharedService.updateCallInfoInLocalStorage(local_session)
          if(!this.callcenterSharedService.lastDialledNumber?.length) 
            {
              this.callcenterSharedService.showDialer = false
            } 
          if(callerSession.callDetails?.tag)
            {
              this.callNotifyService.closeNotification(callerSession.callDetails.tag)
            }

            this.callcenterSharedService.callLog = {
              sid : callerSession.callDetails.call_id,
              call_state : 'established'}
          
        break;

        case SessionState.Terminated:
          let terminatedCallId = invitation.request.headers['X-Signalwire-Callsid']?.[0]?.raw;
          tis.callcenterSharedService.callInvitations = tis.callcenterSharedService.callInvitations.filter(
            (inv) => inv.request.headers['X-Signalwire-Callsid']?.[0]?.raw !== terminatedCallId
          );
          console.warn(`<<<Invitation with callId ${terminatedCallId} removed.>>>, current length ${tis.callcenterSharedService.callInvitations.length}`);
          console.log('Call Ended');
          tis.callcenterSharedService.disconnectMic()
          if (!tis.isOnHold(callerSession)) {//only enters if not on hold
            if (tis.sipDetails.status == 'On Call') {
              tis.updateLastCallTime(invitation);
            }
            tis.closeAgentList();
            tis.callcenterSharedService.clearCompletedCallsfromCallQ();//*check for popup close condition (when merge)
            tis.callDeclineTone();

            if (tis.initialCallQ?.length > 0) {
              tis.callcenterSharedService.ring();
            }
            else {
              tis.callcenterSharedService.stopRing();
            }
            if (!invitation.customValues?.isCallOnHold) {
              tis.callcenterSharedService.stopCallTimer(callerSession)
            }
            this.callcenterSharedService.stopCheckoutTimer(callerSession)
            this.callcenterSharedService._agentStatus.next({ status: StatusValueEnum.OFFLINE, updateStatus: false })

            if(callerSession.callDetails?.tag)
              {
                this.callNotifyService.closeNotification(callerSession.callDetails.tag)
              }

              this.callcenterSharedService.callLog = {
                sid : callerSession.callDetails.call_id,
                call_state : 'terminated'}
          }
          break;
        default:
          break;
      }
    });

  }


  clearUnholdedCallFromCallQ(call_id) {
    
    const index = this.callQ.findIndex(x => x.callDetails.call_id == call_id && x.session.state == 'Terminated')
    if (index != -1) {
      var _callQ = this.callQ
      _callQ.splice(index, 1)
      const callQ = _callQ
      this.callcenterSharedService.callQ = callQ;
    }

  }

  handleDirectCallInvitation(session: any){
    this.getIncomingCallDetailsApi(session)
  }

  autoAcceptConferenceCall(conf_callid: string, invitation: any) {
    const callId = invitation.request.headers['X-Conference-Callsid'][0].raw;
    if(!callId){
      console.warn("Conference Call ID is missing in the invitation headers.");
      return;
    }else{
      //In outbound merge conf_callid will be parentCallId
      let callerSession = this.callQ.filter(x => x.callDetails.call_id == conf_callid || x.callDetails.parent_call_id == conf_callid)[0];

      callerSession.session = invitation;
      callerSession.customValues.isMergedCall = true;
      callerSession.session.accept();
      this.callcenterSharedService.conferenceCallId = null;// to close panel after call ends.
      console.log("%c <--Auto accepted conference call-->", 'color: green;');
      this.listenToSessionStatusChanges(callerSession);

      let local_session = _.merge({}, callerSession)
      local_session.session = {};
      local_session.session["state"] = "Established";
      this.callcenterSharedService.updateCallInfoInLocalStorage(local_session)
    }
  }

  private get initialCallQ() {
    return this.callcenterSharedService.initialCallQ
  }
  private get callQ() {
    return this.callcenterSharedService.callQ
  }

  private get acceptCallQ() {
    return this.callcenterSharedService.acceptCallQ
  }

  private get sipDetails() {
    return this.callcenterSharedService.sipDetails
  }
  private get activeCallerSession() {
    return this.callcenterSharedService.activeCallerSession
  }



  pushtoCallQ(callsession:any)
  {
    const sidePanelConstants:sidePanelConstants ={EventName:CommonPanelEvents.ClosePanel}
    this.commonService.dispatch(new AppEvent(AppEventType.CommonPanelEvent, sidePanelConstants));
    
    callsession.customValues = {
        'isCallOnHold': false, 'isMuted': false, 'callerName': '', 'callType': "inbound",'elapsedTime':0,
        'notification_established':false,'timeout':callsession.callDetails.timeout, 'checkoutTime' :0,
        'isAgentCall':false, 'isCustomerCall':false, 'isAgentOutboundTransferCall':false,'isCustomerOutboundTransferCall':false, 'isAgentConferenceCall':false,
        'isMergedCall': false, 'callStartTime': new Date().getTime(), 'callConnectTime': null, 'call_answered': false
      }
      
      switch (callsession.callDetails?.call_type) {
        case "agent_to_agent_inbound":
          callsession.customValues.isAgentCall = true;
          break;
        case "inbound":
          callsession.customValues.isCustomerCall = true;
          break;
        case "agent_outbound_transfer":
          callsession.customValues.isAgentOutboundTransferCall = true;
          break;
        case "customer_outbound_transfer":
          callsession.customValues.isCustomerOutboundTransferCall = true;
          break;
        case "merged_call":
          callsession.customValues.isAgentConferenceCall = true;
          callsession.customValues.isMergedCall = true;
          break;
      }

      const currentValue = this.callQ;
      //if duplicate exists then remove it from callQ and add active call to callQ
      const filteredValue = currentValue.filter(session => session.callDetails.call_id !== callsession.callDetails.call_id);
      const updatedValue = [...filteredValue, callsession];
      this.callcenterSharedService.callQ = updatedValue;

      console.log('pushcallq', this.callcenterSharedService.callQ )

      this.callcenterSharedService.checkCallTimeout(callsession)

      this.setCallLog(callsession)

  }

  async oncallConnect(callerSession) {
    const hasPermission = await this.callcenterSharedService.requestMicrophonePermission();
    if (!hasPermission) {
      // alert('Microphone access is required to make a call.');
      // this.removeCallFailed(callerSession)
      return;
    }
    callerSession.session.state = 'connect'
    this.callcenterSharedService.updateCallQ(callerSession,'inbound')
    
    console.warn('sip connection state on connect call>>',this.sipConnectionService.isConnected());

    if(!this.sipConnectionService.isConnected()) {
      try {
        this.sipConnectionService.initialiseConnection().then((state) => {
          if (state === RegistererState.Registered) {
                console.log("%c --SIP Registered at " + new Date().toLocaleTimeString()+' before accepting call--',
                 "color: green;;");
                this.inviteCall();
                setTimeout(() => {
                  this.connectTocall(callerSession);
                },200);
          }
          else{
            this.toastr.error('failed to connecting sip.')
            this.removeCallFailed(callerSession) 
          }
        })    
      }
      catch (e) {
        this.toastr.error('Failed to initialize sip connection');
        this.removeCallFailed(callerSession)
      }
    }
    else {
      this.connectTocall(callerSession)
    }
  }


  /**
   * Requesting to route call to this user
   * @param callerSession 
   */
  connectTocall(callerSession,retryCount:number = 0) {

    let callId = callerSession.callDetails?.call_id;
    let invitations = this.callcenterSharedService.callInvitations;
    console.log("%c --invitation count at the time of accepting call-- "+invitations.length, 'color: orange; font-size:15px');

    //If Sip alredy connected then accept the call directly. Else redirect by calling API as no_redirection:false

    /**
     * Check both 'X-Signalwire-Callsid' and 'X-Conference-Callsid' headers to find the matching callId.
     * In conference calls, 'X-Signalwire-Callsid' is null, so we fall back to 'X-Conference-Callsid'.
    */
    const existingInvitationIndex = invitations.findIndex(
      (inv) =>
        inv.request.headers['X-Signalwire-Callsid']?.[0]?.raw === callId ||
        inv.request.headers['X-Conference-Callsid']?.[0]?.raw === callId
    );
    
    if(existingInvitationIndex != -1){
      if(invitations[existingInvitationIndex].state != SessionState.Established && invitations[existingInvitationIndex].state != SessionState.Terminated){
        invitations[existingInvitationIndex].accept();
        console.log("%c <--Accepted the call without redirection-->", 'color: green;');
        let callerSession = this.callQ.filter(x => x.callDetails.call_id == callId)[0]
        callerSession.session = invitations[existingInvitationIndex];
        this.callcenterSharedService.stopCallTimer(callerSession)
        this.listenToSessionStatusChanges(callerSession)
      }
      
    }
    setTimeout(() => {
      console.warn('call connetct try-'+retryCount)
  
      let payload = {
        call_sid: callerSession.callDetails.call_id,
        to_number: callerSession.callDetails.to_number,
        sip_username: 'sip:' + this.callcenterSharedService.sipDetails.sip_uri,
        call_center_enabled: true, 
        project_id: callerSession.callDetails.project_id,
        from_number:callerSession.customValues.isAgentCall == true?callerSession.callDetails.from_number:null,
        call_type  :callerSession.callDetails?.call_type,
        is_override: this.is_override,
        call_recording_enabled: callerSession.callDetails?.call_recording_enabled || false,
        no_redirection: existingInvitationIndex != -1 ? true : false
      };

      this.callcenterSharedService.setAutoAcceptableCallIds(callerSession.callDetails.call_id)
      this.subs = this.callCenterService.connectToCall(payload).subscribe((response) => {
        
            
            if(response.http_status == 200)
              {
                this.is_override = false;
                console.log('callidinconnectcall', callerSession.callDetails.call_id)
                console.log('connec8tcallres',response)
              }
            else
              {
                if(retryCount<=2)
                  {
                      retryCount++
                      this.connectTocall(callerSession,retryCount)
                  }
                else
                {
                  this.toastr.error('Either the call is ended or '+
                    'someone else from your team is attended the call', 'Unable to connect to the call')
                 this.removeCallFailed(callerSession)
                }
       
              }
          },error=>{
            if(retryCount<=2)
              {
                
                  retryCount++
                  this.connectTocall(callerSession,retryCount)
              }
            else
              {
                this.toastr.error('Either the call is ended or '+
                  'someone else from your team is attended the call', 'Unable to connect to the call')
                this.removeCallFailed(callerSession)
              }
             
          })
          
        }, retryCount*1000);
  }

  callDeclineTone() {
    this.declineTone.src = "../../../../assets/ringtone/decline tone.mp3"
    this.declineTone.play();
  }

  callAcceptTone() {
    this.acceptTone.src = "../../../../assets/ringtone/pickup tone.mp3"
    this.acceptTone.play();
  
  }

  getIncomingCallDetailsApi(session: any) {

    const callId = session.request.headers['X-Signalwire-Callsid'][0].raw;
    if (callId) {
      this.subs = this.callCenterService.getCallDetails(callId).subscribe(response => {

        if (response.http_status == 200) {
          this.callcenterSharedService.ring();
          this.pushtoCallQ({callDetails:response.data.result, session:{state:'push'}})
          this.callcenterSharedService.popupOpenStatus.next(true)
        } else {
          console.log("Error while fetching call details");
        }
      });
    } 
  }

  getDetailsofNonCallCenterNumber(session: any) {

    let toNumber = session.request.headers['X-Dialed-Number'][0].raw || null;
    let fromNumber = session.remoteIdentity.uri.user;
    this.subs = this.callCenterService.getNonCallCenterDetails(toNumber, fromNumber).subscribe(resp => {
      if (session.customValues) {
        session.customValues.callerName = resp.data?.result?.users_name || null;
        session.customValues.callDetails = resp
      }
    },
      (error) => {
        console.error(error);
        this.toastr.error("Error Occurred")
      });
  }

  isOnHold(session) {
    if (session.customValues.isCallOnHold) {
      return true;
    } else {
      return false;
    }
  }

  clearCallQ() //to clear all callq
  {
    this.callcenterSharedService.callQ = [];
  }

  updateLastCallTime(endedCall) {

    // const isCallPresent = this.acceptCallQ.includes(endedCall);
    // if (isCallPresent) {
    //   let date = new Date();
    //   let payload: UserStatusUpdateRequest = new UserStatusUpdateRequest();
    //   payload.call_end_time = this.datePipe.transform(date, 'yyyy-MM-dd\'T\'HH:mm:ss.SSSZZZZZ')
    //   this.subs = this.callCenterService.updateLastCallTime(payload).subscribe(responds => { }
    //     , (err) => {
    //       console.log(err);
    //     });
    // }
  }

  get holdCallQ() {
    return this.acceptCallQ?.filter(session => session.customValues.isCallOnHold == true)
  }
  public holdCall(callerSession,retryCount = 0) {

    const call_Details = callerSession.callDetails
    const is_hold = callerSession.customValues.isCallOnHold;

    if(retryCount<=1)
      {
        if (is_hold) //onunhold
        {
          if (this.callcenterSharedService.activeCallerSession) {
    
            if (this.callcenterSharedService.activeCallerSession.callDetails.call_id != call_Details.call_id) {
    
              this.toastr.warning("Please hold current call")
              return
            }
    
          }
        }
        // const options = {
        //   sessionDescriptionHandlerModifiers: !callerSession.customValues.isCallOnHold ? [Web.holdModifier] : []
        // }
        if (callerSession.session.state == 'Established' && !is_hold) {
        //  callerSession.session.invite(options).then(() => {
          //  if (!callerSession.customValues.isCallOnHold) {//put on hold
              let payload = {
                call_sid: call_Details.call_id, //this.call_Details.call_id,
                sip:this.callcenterSharedService.sipDetails.sip_uri,
                to_number: call_Details.to_number,//this.call_Details.call_to
                project_id:call_Details.project_id,
                call_type  :callerSession.callDetails?.call_type,
                call_recording_enabled: callerSession.callDetails?.call_recording_enabled || false
              };
              this.callcenterSharedService.setAutoAcceptableCallIds(call_Details.call_id)
              this.subs = this.callCenterService.putCallOnHold(payload).subscribe(response => {
    
                if(response.http_status == 200)
                  {
                    this.closeAgentList();
                    callerSession.customValues.isCallOnHold = true;
                    let local_session = _.merge({}, callerSession)
                    local_session.session = {};
                    local_session.session["state"] = "Terminated";
                    this.callcenterSharedService.updateCallInfoInLocalStorage(local_session)
                    if(document.visibilityState === "hidden" ){
                      this._fireMessagingService.showUnholdCallNotification(callerSession.callDetails)
                    }
                  }
                else
                  {
                    retryCount++
                    if(retryCount<=1)
                      {
                        this.holdCall(callerSession,retryCount)
                        console.warn("hold call failed-"+ retryCount);
                      }
                    else
                      {
                        this.handleHoldFailed(callerSession,true)
                      }
                  }
              },
                (error) => {
                  retryCount++
                  if(retryCount<=1)
                    {
                      this.holdCall(callerSession,retryCount)
                      console.warn("hold call failed-"+ retryCount);
                    }
                  else
                    {
                      this.handleHoldFailed(callerSession,true)
                    }
                });
            //}
           // callerSession.customValues.isCallOnHold = re_value;
            // this.callcenterSharedService.setAutoAcceptableCallIds(callerSession.session.request.headers['X-Signalwire-Callsid'][0].raw)
          // })
          //   .catch((error) => {
          //     console.warn(`Failed to ${!callerSession.customValues.isCallOnHold ? 'hold' : 'unhold'} call. Error:`, error);
          //   }); 
        } else {//release from hold
          if (is_hold && callerSession.session.state == 'Terminated') {
       
            let payload = {
              call_sid: call_Details.call_id,
              to_number: this.iscallcenterEnabled ? call_Details.to_number : callerSession.session.request.headers['X-Dialed-Number'][0].raw,
              sip_username: 'sip:' + this.sipDetails.sip_uri,
              call_center_enabled: this.iscallcenterEnabled,
              project_id:call_Details.project_id,
              call_type  :call_Details?.call_type,
              call_recording_enabled: call_Details?.call_recording_enabled || false
            };
            // callerSession.customValues.isCallOnHold = true
            this.callcenterSharedService.setAutoAcceptableCallIds( call_Details.call_id)
            this.subs = this.callCenterService.releaseCallFromHold(payload).subscribe((response) => {
             // if (response.data?.result.call_ended) {
                if(response.http_status != 200)
                  {            
                    this.toastr.warning('Caller hung up');
                    this.callcenterSharedService.removeCurrentCallsfromCallQ(call_Details.call_id)
                    this.callcenterSharedService.updateCallInfoInLocalStorage(callerSession, true)
                    this.callcenterSharedService.checkQandCloseCallCenterPanel();
                  }
                else if(response.http_status == 200)
                  {
                    callerSession.customValues.isCallOnHold = false
                    let local_session = _.merge({}, callerSession)
                    local_session.session = {};
                    local_session.session["state"] = "Established";
                    this.callcenterSharedService.updateCallInfoInLocalStorage(local_session)
                    if(document.visibilityState === "hidden" ){
                      this._fireMessagingService.showHoldCallNotification(callerSession.callDetails)
                    }
                  }
          
             // }
            },
              (error) => {
                console.log('error', error);
    
              });
          }
         /**
          * if call failed hold and showing as hold without terminate
          */
          else if(is_hold && callerSession.session.state == 'Established')
            {
              this.handleHoldFailed(callerSession,false)
              // callerSession.customValues.isCallOnHold = false
              // //this.callcenterSharedService.handleMic(true)
              // this.callcenterSharedService.muteCall(false)
              
            }
        }
      }
  }

  handleHoldFailed(callerSession:any,value:boolean)
  {
    callerSession.customValues.isCallOnHold = value
    this.callcenterSharedService.muteCallHold(callerSession,value)
  }

  pickkCall(session) {

    if (this.activeCallerSession) {
      this.toastr.warning("Please Hold Current Ongoing Call !")
      return
    }
    //if (this.checkOutboundCallIsActive() == false) {
    var options = {
      'mediaConstraints': {
        'audio': { deviceId: this.audioInputSource ? { exact: this.audioInputSource } : undefined }
        , 'video': { deviceId: this.videoInputSource ? { exact: this.videoInputSource } : undefined }
      }
    };
    if (session.state == 'Initial') {
      this.callcenterSharedService.stopRing();
      session.accept(options);
      //this.movetoAcceptedCalls(session)
      localStorage.setItem('isOnCall', 'true');
      //this.callCenterShared.activeCallerSession = session

    }
    //  }
  }

  rejectCallApi(call_id: string){
    if(!call_id){
      console.log("Call id not found");
      return;
    }
    let userId = this.callCenterService.userId.toString();
    this.subs = this.callCenterService.rejectCall(call_id, userId).subscribe({
      next: (response) => {
        if(response.http_status != 200){
          console.log("Error in reject api", response.message);
        }
      },
      error: (error) => {
        console.warn('error in reject api', error);
      }
    });
  }

  rejectCall(callerSession: any) {
    this.rejectCallApi(callerSession.callDetails.call_id);
    this.callcenterSharedService.stopCheckoutTimer(callerSession)
    
    if(callerSession.session.state == "push")
      { 
        this.removeCallFailed(callerSession)
        if (this.initialCallQ?.length > 0) {
          this.callcenterSharedService.ring();
        }
        else {
          this.callcenterSharedService.stopRing();
        }
      }
    if (callerSession.session.state == 'Initial') {
      callerSession.session.reject().then(() => {
        this.callDeclineTone();
      }).catch(() => {
        this.toastr.error('Failed to reject call')
        this.removeCallFailed(callerSession)
        if (this.initialCallQ?.length > 0) {
          this.callcenterSharedService.ring();
        }
        else {
          this.callcenterSharedService.stopRing();
        }
      })
    }
    if(callerSession.callDetails.tag)
      {
        this.callNotifyService.closeNotification(callerSession.callDetails.tag)
      }

  }

  endCall(callerSession) {

    const call_Details = callerSession.callDetails

    if (callerSession.session.state == 'Established' && callerSession.customValues.isCallOnHold == false) {
      callerSession.session.bye().then(() => {
        this.closeAgentList();
        this.callDeclineTone();
      }).catch(() => {
        this.toastr.error('Failed to end call')
        this.removeCallFailed(callerSession)
      })
    } 
     /**
      * if call failed hold and showing as hold without terminate
      */
    else if(callerSession.session.state == 'Established' && callerSession.customValues.isCallOnHold == true)
      {
        callerSession.session.bye().then(() => {
          this.callDeclineTone();
          this.closeAgentList();
          this.removeCallFailed(callerSession);
        }).catch(() => {
          this.toastr.error('Failed to end call')
          this.removeCallFailed(callerSession)
        })
      }
    else {
      let payload = {
        call_sid: call_Details.call_id,//this.call_Details.call_id,
        to_number: call_Details.to_number,
        username: null,
        project_id:call_Details.project_id,
        call_type  :call_Details?.call_type,
        call_recording_enabled: call_Details?.call_recording_enabled || false
      };
      this.subs = this.callCenterService.endCallFromHold(payload).subscribe(response => {
        if(response.http_status == 200)
          {
            this.closeAgentList();
            callerSession.customValues.isCallOnHold = false;
            this.callcenterSharedService.clearCompletedCallsfromCallQ(false);
          }
        else
          {
            this.toastr.error("Failed to end call,"+response.message[0]);
            this.removeCallFailed(callerSession)
          }
      },
        (error) => {
          this.toastr.error("Failed to end call");
          this.removeCallFailed(callerSession)
        });
    }
  
  }

  removeCallFailed(callerSession)
  {
    this.closeAgentList();
    this.callDeclineTone()
    this.callcenterSharedService.removeFromCallQ(callerSession.callDetails.call_id)
  }

  destroy() {
    this.subs.unsubscribe();
  }

  setCallLog(callerSession:any)
  {
    console.log('--callsession_log',callerSession)
    const _callLog:any={
     
    sid : callerSession.callDetails.call_id,
    company_users_name : callerSession?.customValues?.isAgentCall? 
                           callerSession.callDetails.agent_name : callerSession.callDetails.company_users_name,
    call_from : callerSession.callDetails.from_number,
    call_to : callerSession.callDetails.to_number,
    date_created : new Date(),
    recording_url_list : null,
    accepted_by_name : this.sipDetails.associated_username,
    suborg_name :  this.getCurrentSuborgName,
    incoming_route_name : callerSession.callDetails.incoming_route_name,
    status : 'In progress',
    direction : 'inbound',
    isAccepted : false
    }

    
    this.callcenterSharedService.callLog = _callLog;

    console.log('callLog',_callLog)
  }

  closeAgentList(){
    this.showTransferList = false;
    this.callcenterSharedService.showMergeList = false;
  }

  transferCall(callerSession: any, transfer_option:string, toUserId:number) {
    if(callerSession?.session?.state == 'Established'){
      let payload: TransferCallPayload = new TransferCallPayload();
      payload.call_sid = callerSession.callDetails.call_id;
      payload.project_id = callerSession.callDetails.project_id;
      payload.transfered_to = toUserId;
      payload.transfered_by = +localStorage.getItem(AppConstants.USER_ID);
      payload.from_number = callerSession.callDetails.from_number;
      payload.to_number = callerSession.callDetails.to_number;
      payload.call_recording_enabled = callerSession.callDetails.call_recording_enabled;
      payload.incoming_route_name = callerSession.callDetails.incoming_route_name;
      payload.transfer_option = transfer_option;
      payload.call_type = callerSession?.callDetails?.call_type||null;
      this.subs = this.callCenterService.transferCall(payload).subscribe({
        next:resp => {
          if(resp.http_status == 200 && resp.data?.result?.status){
            this.closeAgentList();
            const callTransferredTo = resp.data?.result?.call_transfered_to || 'Agent';
            this.toastr.success(`Call has been successfully transferred to ${callTransferredTo}`);
          }
          else{
            this.toastr.error("Failed to transfer call");
          }
        },
        error:err => {
          this.toastr.error("Failed to transfer call","Internal server error");
        }
      })
    }else{
      this.toastr.error("No active call to transfer","Error");
      return;
    }
  }

  mergeAgentToInboundCall(callerSession: any, toUserId:number){
    console.log('callerSession in merge agent',callerSession);
    
    if(callerSession?.session?.state == 'Established'){
      this.callcenterSharedService.conferenceCallId = callerSession.callDetails.call_id;
      // payload for agent call merge on inbound call
      let payload: InboundMergeAgentPayload = new InboundMergeAgentPayload();
      payload.call_id = callerSession.callDetails.call_id;
      payload.project_id = callerSession.callDetails.project_id;
      payload.from_number = callerSession.callDetails.from_number;
      payload.call_merged_from = +localStorage.getItem(AppConstants.USER_ID);
      payload.call_merged_to = toUserId;
      payload.call_already_in_conference = callerSession.customValues.isMergedCall;

      const api = this.callCenterService.mergeAgentToInboundCall(payload);
      this.mergeCallApi(api, callerSession);
    }
  }

  mergeContactToInboundCall(callerSession: any, toNumber:string){
    console.log('callerSession in merge contact',callerSession);
    
    if(callerSession?.session?.state == 'Established'){
      this.callcenterSharedService.conferenceCallId = callerSession.callDetails.call_id;
      // payload for customer call merge on inbound call
      let payload: InboundMergeCustomerPayload = new InboundMergeCustomerPayload();
      payload.call_id = callerSession.callDetails.call_id;
      payload.call_merged_from = +localStorage.getItem(AppConstants.USER_ID);
      const number = localStorage.getItem(AppConstants.SEND_AS_NUMBER) || '';
      payload.call_merged_from_send_as = number.startsWith('+1') ? number.slice(2) : number;
      payload.to = toNumber;
      payload.call_already_in_conference = callerSession.customValues.isMergedCall;
      const api = this.callCenterService.mergeCustomerToInboundCall(payload);
      this.mergeCallApi(api, callerSession, toNumber);
    }
  }

  private mergeCallApi(api, callerSession, toNumber?:string){
    this.subs = api.subscribe({
      next:resp => {
        if(resp.http_status === 200 && resp.data?.result?.status){
          this.callcenterSharedService.showMergeList = false;
          this.toastr.success("Merge request sent successfully");
        }else{
          this.toastr.error("Unable to add "+toNumber+" to the conference call.","Failed");
        }
      },
      error:err => {
        this.callcenterSharedService.conferenceCallId = null;
        this.toastr.error("Failed to merge call","Internal server error");
      },
      complete:() => {
        if(callerSession.customValues.isMergedCall){
          this.callcenterSharedService.conferenceCallId = null;
        }
      }
    })
  }

}
