import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { ShortNamePipe } from 'app/shared/pipes/short-name.pipe';
import { PhoneNumberPipe } from 'app/shared/pipes/phone-number.pipe';
import { DataFilterGroupRequest, GFilterGroup, GFilterParam, GSortParam } from 'app/core/models/grid-filter.models';
import { DidsService } from '../../services/dids.service';
import { SubSink } from 'subsink';
import { CallLog } from '../../models/reports.models';
import { CallReportsDataHelper } from '../../helpers/call-report-helpers';
import { timezoneCheckPipe } from 'app/shared/pipes/timezone.pipe';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatIconModule } from '@angular/material/icon';
import { AppConstants } from 'app/core/constants/app.constants';
import { InitiateCallDirective } from 'app/shared/directives/initiate-call.directive';
import { ToolTipDirective } from 'app/shared/directives/tool-tip.directive';
import { CallCenterSharedService } from '../../services/call-center-shared.service';
import { environment } from 'environments/environment';


@Component({
  selector: 'recent-call-history',
  standalone: true,
  imports: [CommonModule,ShortNamePipe,PhoneNumberPipe,timezoneCheckPipe, TitleCasePipe, NgxSkeletonLoaderModule, MatIconModule,
    InitiateCallDirective, PhoneNumberPipe, ToolTipDirective
  ],
  templateUrl: './recent-call-history.component.html',
  styleUrl: './recent-call-history.component.scss'
})
export class RecentCallHistoryComponent implements  OnDestroy {

  @ViewChild('history') private historyBlock: ElementRef;

  @ViewChild(InitiateCallDirective) private initiateCallDirective: InitiateCallDirective;

  sort: GSortParam[] =[{ colname: 'date_created', direction: 'desc' }];

  private subs: SubSink = new SubSink();

  @Output() addForCall=new EventEmitter<any>();

  rows: CallLog[] = []

  @Input() isAllSuborg:boolean = false;

  private defaultFilter: GFilterParam[] = CallReportsDataHelper.CallHistoryByUser;

  isLoading: boolean = false;

  public fileUrl: string = environment.imgURL;

  bottomReached: boolean = false;

  currentCount: number = 0;

  totalCount:number = 0;

  loadingMore: boolean = false;

  showScrollButton: boolean;

  userId: any;
  agentSipUri: string;

  currentSip: string;

  constructor(private didService: DidsService,
    private _cdr: ChangeDetectorRef,
    private callcenterShared:CallCenterSharedService,
    private _phoneNumberPipe: PhoneNumberPipe){
      this.userId = didService.userId;
      this.currentSip =this._phoneNumberPipe.transform(localStorage.getItem(AppConstants.SIP_URI))
      
    }

  ngOnInit(){
    this.getData(false,[{ 'conditions': this.defaultFilter, 'operator': 'AND' }]);
    this.agentSipUri = localStorage.getItem(AppConstants.SIP_URI)
    //this.getImage()
  }

  trackByFn(index: number, item: any): any
  {
    return item.sid || index;
  }

  getData(loadMore: boolean = false,filters: GFilterGroup[] = [], offset: number = 0) {
    loadMore?this.isLoading = false:this.isLoading = true;
    this.loadingMore = true;
    let request: DataFilterGroupRequest = {
      base_filters: [],
      filters: filters,
      sort: this.sort
  };

    this.subs.sink = this.didService.getCallHistory(request, offset).subscribe({
      next:(response) => {
        if (loadMore) {
          this.rows = [...this.rows, ...response.data?.result];
        } else {
            this.rows = response.data?.result;
        }
        this.isLoading = false;
        this.bottomReached = false;
        this.currentCount += response.data?.count;
        this.totalCount = response.data?.total_count;
        this._cdr.markForCheck();
      },
      error: (error)=>{
        console.error('error',error);
      },
      complete:()=>{
        this.loadingMore = false;
      }
    });
  }

  onScroll(historyBlock: HTMLElement): void {
    const scrollTop = historyBlock.scrollTop;
    const scrollHeight = historyBlock.scrollHeight;
    const offsetHeight = historyBlock.offsetHeight;
    const isScrolledToBottom = Math.floor(scrollTop + offsetHeight) >= scrollHeight-1;

    if(scrollTop > 200){
      this.showScrollButton = true;
    }else{
      this.showScrollButton = false;
    }

    if(isScrolledToBottom && !this.bottomReached){
      this.bottomReached = true;
      if (!this.isLoading && (this.currentCount< this.totalCount)) {
        this.isLoading = true;
        let offset = this.currentCount;
        // API to get more history
        this.getData(true,[{ 'conditions': this.defaultFilter, 'operator': 'AND' }],offset);
      }
      this._cdr.markForCheck();
    }else{
      this.bottomReached = false;
    }
  }

  scrollToTop() {
    this.historyBlock.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
  }

  callNumber(number: string): void {
    if (number) {
      if (number.includes('@')) {
        number = number.split('@')[0];
        if(number.includes(':')){
          number = number.split(':')[1];
        }
      }
      this.addForCall.emit(number);
    }
  }

  getSipFromUri(value){
    const parts = value.split(':');
    if (parts.length > 1) {
      const subParts = parts[1].split('@');
      if (subParts.length > 1) {
        return subParts[0];
      }
    }
    return '';
  }

  getNumbertoCall(row){
    if(row.created_user == this.userId){
      return row.call_to;
    }else if(row.created_user != this.userId){
      return row.call_from;
    }else{
      return row.call_from;
    }
  }

  getName(row: any){
    if(row.created_user == this.userId){ // Outbound call
      if(row?.accepted_by_name?.trim())
        return row?.accepted_by_name.trim();
      else if(row?.call_to_name?.trim())
        return row?.call_to_name.trim();
      else if(row?.company_users_name?.trim())
        return row?.company_users_name.trim();
      else
        return 'Unknown'
    }else{ // Inbound or missed call
      if(row?.company_users_name?.trim())
        return row?.company_users_name?.trim();
      else if(row?.created_by?.trim())
        return row?.created_by.trim();
      else
        return 'Unknown'
    }
  }

  onAvatarMouseEnter(){
    this.initiateCallDirective.hide();
  }

  onAvatarMouseLeave(){
      this.initiateCallDirective.show();
  }

  getImage(row):string{
  
    // const row = {
    //     "answered_name": null,
    //     "is_answered": false,
    //     "is_spam": false,
    //     "call_from": "100@ring2voice-31add575bd42.sip.signalwire.com",
    //     "is_voicemail": false,
    //     "start_time": "2024-11-13T04:56:44.420694Z",
    //     "is_analyzed": false,
    //     "formated_from_number": "100",
    //     "parent_call_sid": null,
    //     "is_ring_started": false,
    //     "call_accepted_by": null,
    //     "comments": 0,
    //     "suborg": 28,
    //     "rec_duration": 0,
    //     "call_created_by": null,
    //     "modified_user": null,
    //     "source": null,
    //     "created_on": "2024-11-13T04:56:44.431741Z",
    //     "price": null,
    //     "status": "failed",
    //     "incoming_route_settings": null,
    //     "date_modified": null,
    //     "created_user": 28,
    //     "direction": "outbound",
    //     "duration": 0,
    //     "call_to": "sip:101@ring2voice-31add575bd42.sip.signalwire.com",
    //     "date_created": "2024-11-13T04:56:44.420705Z",
    //     "price_unit": "USD",
    //     "child_call_to": null,
    //     "sid": "20002f78-626d-4845-9036-2747dd0c76bd",
    //     "stop_push_sent": false,
    //     "formated_to_number": "101",
    //     "real_status": null,
    //     "tenant": 26,
    //     "is_active": true,
    //     "answered_by": null,
    //     "recording_url": null,
    //     "modified_on": "2024-11-13T04:56:50.860984Z",
    //     "is_delete": false,
    //     "end_time": "2024-11-13T04:56:50.860787Z",
    //     "forwarded_from": null,
    //     "caller_name": null,
    //     "tenant_name": "ABC groups",
    //     "suborg_name": "ABC groups",
    //     "incoming_route_name": null,
    //     "recording_url_list": null,
    //     "is_sip": true,
    //     "accepted_by_name": " ",
    //     "accepted_by_sip_name": null,
    //     "created_by_name": " ",
    //     "created_by_sip_name": null,
    //     "call_to_count": 8,
    //     "call_to_trim": "sip:101",
    //     "call_to_after_trim": "101",
    //     "call_to_after_trim_2": "01",
    //     "call_to_after_trim_3": "1",
    //     "call_to_length": 3,
    //     "call_to_check_sip": null,
    //     "company_users_name": null,
    //     "company_users_id": null,
    //     "company_users_title": null,
    //     "company_name": null,
    //     "company_id": null,
    //     "company_users_phone": null,
    //     "company_users_mobile": null,
    //     "company_users_email": null,
    //     "is_missed_call": false,
    //     "is_sip_to": true,
    //     "substring2": "100@",
    //     "substring3": "100",
    //     "substring4": "101@ring",
    //     "substring5": "101",
    //     "final_call_to": 101,
    //     "call_to_name_1": "Josh V",
    //     "call_to_name_2": null,
    //     "call_to_name": "Josh V",
    //     "call_accepted_by_image": null,
    //     "created_user_image": "11c361c4-57b4-48ba-827e-b6d5facf10aa",
    //     "call_to_image": "eec1a4ec-72e4-4bd5-bc19-4d97382c76f5",
    //     "call_from_image": "11c361c4-57b4-48ba-827e-b6d5facf10aa",
    //     "created_by": "Sainul Abid",
    //     "modified_by": " ",
    //     "modified_by_image": null
    //   }
    
    if(row.direction == 'outbound' && this._phoneNumberPipe.transform(row.call_to) == this.currentSip && row.call_accepted_by != this.userId
       && row.call_from_image) //check it is a missed call
    {
      return this.fileUrl +row.call_from_image+'.jpg';
    }
    else if(row.call_accepted_by==this.userId) // check inbound call
    {
      return this.fileUrl +row.call_from_image+'.jpg';
    }
    else if(row.direction == 'outbound' && row.call_to_image)
    {
      return this.fileUrl +row.call_to_image+'.jpg';
    }
    else if(row.direction == 'inbound' && row.call_from_image)
    {
      return this.fileUrl +row.call_from_image+'.jpg';
    }
    else
    {
      return null;
    }

  }
  
  ngOnDestroy(): void {
      this.subs.unsubscribe()
  }

}
