import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConstants } from 'app/core/constants/app.constants';
import { BaseService } from 'app/core/services/base.service';
import { environment } from 'environments/environment';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FireMessagingService extends BaseService {

  pushNotification = new BehaviorSubject(null);

  env=environment;
  

  constructor(private http: HttpClient, private toaster:ToastrService) {  super();

  }

  count = 0
  requestPermission() {
    if('Notification' in window){
      Notification.requestPermission().then(permission =>{
        console.warn('--notification permission', permission)
        if(permission === 'granted'){
          console.log('Notification permission granted');

          const messaging = getMessaging();
          getToken(messaging, { vapidKey: this.env.fireBase.vapidKey})
            .then(
              (currentToken) => {
                if (currentToken) {
                  console.log("fire msg token.....");
                  console.log(currentToken);
                  localStorage.setItem(AppConstants.DEVICE_ID, currentToken);
                  setTimeout(() => {
                    this.listen();//listen to incoming notification
                  }, 200);
                  const body = {
                      device_id:currentToken,
                      source: "WEB", 
                  }
  
                  this.registerDeviceToken(body).subscribe(res=>{
                    console.log('token-res',res)
                  })
                } else {
                  console.log('No registration token available. Request permission to generate one.');
                }
            })
            .catch((err) => {
              if(this.count==0){
                setTimeout(() => {
                  this.requestPermission()
                  this.count++;
                }, 100);
                console.log("error occured while  retrieving token first time", err)
              }
              else{
                this.toaster.error('An error occurred while retrieving token.', err);
                console.log("error occured while retrieving token second time", err)
              }
            });

        }
        else if(permission === 'denied' || permission === 'default'){
          this.toaster.error("To function features like calls and chats, its required to have notification permission enabled in your browser",
                              'Notification is not enabled!',{closeButton: true, disableTimeOut: true, tapToDismiss: false})
        }
      });
    }else{
      console.error('This browser does not support notifications.');
    }
  }
  
  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.warn('Message received in service. ', payload);
      this.pushNotification.next(payload);
    });
  }

  registerDeviceToken(body:any)
  {    
    let userid = localStorage.getItem(AppConstants.USER_ID);
    return this.http.post<any>(this.baseURL+'/users/'+userid+'/device_token/',body);
  }

  async showHoldCallNotification(_data: any) {
    if ('serviceWorker' in navigator) {
      const registrations = await navigator.serviceWorker.getRegistrations();
      registrations.forEach(registration => {
        registration.active?.postMessage({ type: 'HOLD_CALL', data: _data});
      });
    }
  }

  async showUnholdCallNotification(_data: any) {
      if ('serviceWorker' in navigator) {
        const registrations = await navigator.serviceWorker.getRegistrations();
        registrations.forEach(registration => {
          registration.active?.postMessage({ type: 'UNHOLD_CALL', data: _data });
        });
      }
  }


}
