import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

export function phoneValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    const value = control.value;

    if (!value) {
      return null; // Don't validate empty values; use `required` separately if needed
    }

    // Remove non-numeric characters
    const numericValue = value.replace(/[^\d]/g, '');

    console.log('numericValue', numericValue);

    // Check length
    if (numericValue.length < 10 || numericValue.length > 15) {
      return { invalidPhone: true };
    }

    // Validate format (optional)
    // const validFormats = /^(\(\d{3}\)\s?\d{3}-\d{4}|\d{10,15})$/; ///^(1\s?)?(\(\d{3}\)\s?\d{3}-\d{4}|\d{10,15})$/; // /^(\(\d{3}\)\s?\d{3}-\d{4}|\d{10,15})$/;

    // if (!validFormats.test(value)) {
    //   return { invalidPhone: true };
    // }

    return null; // Valid
  };
}

@Directive({
  selector: '[PhoneValidator]',
  standalone: true,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: PhoneValidatorDirective,
      multi: true,
    },
  ],
})
export class PhoneValidatorDirective implements Validator {
  validate(control: AbstractControl): { [key: string]: any } | null {
    return phoneValidator()(control);
  }
}
