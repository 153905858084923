
import { Injectable } from '@angular/core';
import { Registerer, UserAgent, UserAgentDelegate, UserAgentOptions } from 'sip.js';
import { CallCenterSharedService } from './call-center-shared.service';
import { set } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class SipConnectionService {

  constructor(
    private callcenterSharedService:CallCenterSharedService
    ) { }

  async  initialiseConnection() {

    console.log('sipDetails-',this.callcenterSharedService.sipDetails)
    const transportOptions = {
      server: this.callcenterSharedService.sipDetails.websocket_uri,
      keepAliveInterval: 20
    };
    const uri = UserAgent.makeURI('sip:' + this.callcenterSharedService.sipDetails.sip_uri);
    const userAgentOptions: UserAgentOptions = {
      authorizationPassword: this.callcenterSharedService.sipDetails.password,
      authorizationUsername:  this.callcenterSharedService.sipDetails.agent_name,
      transportOptions,
      uri,
      sessionDescriptionHandlerFactoryOptions: {
        earlyMedia: true,
        inviteWithSdpTimeout: 10000,
        iceCheckingTimeout: 5000,
        iceGatheringTimeout: 5000,
        inviteWithoutSdp: true,
        alwaysAcquireMediaFirst: true,
        iceServers: [
          {
            urls: 'stun:stun.l.google.com:19302'
          }
        ]
      }
    };
    this.callcenterSharedService.connection = new UserAgent(userAgentOptions);
   // this.setUpEventListeners();
    // this.sipOutBoundFnService.connection = this.connection


    // console.log('connection_data', this.sipOutBoundFnService.connection)
    return new Promise((resolve, reject) => {

      const registerer = new Registerer(this.callcenterSharedService.connection, {expires: 300});
      this.callcenterSharedService.connection.start().then(() => {
        registerer.register().then(() => {
          registerer.stateChange.addListener((state) => {

            resolve(state)
          });
        }).catch((errorResponse) => {
          
          console.log('errorResponse : ' + errorResponse);
          reject()

          this.callcenterSharedService.isSIPRegistered$.next(false)
          alert("Registration Failed. Try again")
        });
      }).catch(() => {
        alert("Connection error. Reload and try again")
      })

    });


  }

  // private setUpEventListeners(): void {
  //   const delegate:UserAgentDelegate  = {
  //     onConnect: () => {
  //       console.log('SIP.js UserAgent connected');
  //       this.connected = true;
  //     },
  //     onDisconnect: (error?: Error) => {
  //       if (error) {
  //         console.error('SIP.js UserAgent disconnected due to error', error);
  //       } else {
  //         console.log('SIP.js UserAgent disconnected');
  //       }
  //       this.connected = false;
  //     }
  //   };
  //   this.callcenterSharedService.connection.delegate  = delegate;
  // }

  // public isConnected(): boolean {
  //   return this.connected;
  // }




  public isConnected(): boolean {
    return this.callcenterSharedService.connection && this.callcenterSharedService.connection.transport
     && this.callcenterSharedService.connection.transport.isConnected();
  }

}
