import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { ShortNamePipe } from 'app/shared/pipes/short-name.pipe';
import { PhoneNumberPipe } from 'app/shared/pipes/phone-number.pipe';
import { DataFilterGroupRequest, GFilterGroup, GFilterParam, GSortParam } from 'app/core/models/grid-filter.models';
import { DidsService } from '../../services/dids.service';
import { SubSink } from 'subsink';
import { CallLog } from '../../models/reports.models';
import { CallReportsDataHelper } from '../../helpers/call-report-helpers';
import { timezoneCheckPipe } from 'app/shared/pipes/timezone.pipe';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatIconModule } from '@angular/material/icon';
import { AppConstants } from 'app/core/constants/app.constants';
import { InitiateCallDirective } from 'app/shared/directives/initiate-call.directive';
import { ToolTipDirective } from 'app/shared/directives/tool-tip.directive';
import { CallCenterSharedService } from '../../services/call-center-shared.service';
import { environment } from 'environments/environment';


@Component({
  selector: 'recent-call-history',
  standalone: true,
  imports: [CommonModule,ShortNamePipe,PhoneNumberPipe,timezoneCheckPipe, TitleCasePipe, NgxSkeletonLoaderModule, MatIconModule,
    InitiateCallDirective, PhoneNumberPipe, ToolTipDirective
  ],
  templateUrl: './recent-call-history.component.html',
  styleUrl: './recent-call-history.component.scss'
})
export class RecentCallHistoryComponent implements  OnDestroy {

  @ViewChild('history') private historyBlock: ElementRef;

  @ViewChild(InitiateCallDirective) private initiateCallDirective: InitiateCallDirective;

  sort: GSortParam[] =[{ colname: 'date_created', direction: 'desc' }];

  private subs: SubSink = new SubSink();

  @Output() addForCall=new EventEmitter<any>();

  rows: CallLog[] = []

  @Input() isAllSuborg:boolean = false;

  private defaultFilter: GFilterParam[] = CallReportsDataHelper.CallHistoryByUser;

  isLoading: boolean = false;

  public fileUrl: string = environment.imgURL;

  bottomReached: boolean = false;

  currentCount: number = 0;

  totalCount:number = 0;

  loadingMore: boolean = false;

  showScrollButton: boolean;

  userId: any;
  agentSipUri: string;

  currentSip: string;

  constructor(private didService: DidsService,
    private _cdr: ChangeDetectorRef,
    private callcenterShared:CallCenterSharedService,
    private _phoneNumberPipe: PhoneNumberPipe){
      this.userId = didService.userId;
      this.currentSip =this._phoneNumberPipe.transform(localStorage.getItem(AppConstants.SIP_URI))
      
    }

  ngOnInit(){
    this.getData(false,[{ 'conditions': this.defaultFilter, 'operator': 'AND' }]);
    this.agentSipUri = localStorage.getItem(AppConstants.SIP_URI)
    //this.getImage()
  }

  trackByFn(index: number, item: any): any
  {
    return item.sid || index;
  }

  getData(loadMore: boolean = false,filters: GFilterGroup[] = [], offset: number = 0) {
    loadMore?this.isLoading = false:this.isLoading = true;
    this.loadingMore = true;
    let request: DataFilterGroupRequest = {
      base_filters: [],
      filters: filters,
      sort: this.sort
  };

    this.subs.sink = this.didService.getCallHistory(request, offset).subscribe({
      next:(response) => {
        if (loadMore) {
          this.rows = [...this.rows, ...response.data?.result];
        } else {
            this.rows = response.data?.result;
        }
        this.isLoading = false;
        this.bottomReached = false;
        this.currentCount += response.data?.count;
        this.totalCount = response.data?.total_count;
        this._cdr.markForCheck();
      },
      error: (error)=>{
        console.error('error',error);
      },
      complete:()=>{
        this.loadingMore = false;
      }
    });
  }

  onScroll(historyBlock: HTMLElement): void {
    const scrollTop = historyBlock.scrollTop;
    const scrollHeight = historyBlock.scrollHeight;
    const offsetHeight = historyBlock.offsetHeight;
    const isScrolledToBottom = Math.floor(scrollTop + offsetHeight) >= scrollHeight-1;

    if(scrollTop > 200){
      this.showScrollButton = true;
    }else{
      this.showScrollButton = false;
    }

    if(isScrolledToBottom && !this.bottomReached){
      this.bottomReached = true;
      if (!this.isLoading && (this.currentCount< this.totalCount)) {
        this.isLoading = true;
        let offset = this.currentCount;
        // API to get more history
        this.getData(true,[{ 'conditions': this.defaultFilter, 'operator': 'AND' }],offset);
      }
      this._cdr.markForCheck();
    }else{
      this.bottomReached = false;
    }
  }

  scrollToTop() {
    this.historyBlock.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
  }

  callNumber(number: string): void {
    if (number) {
      if (number.includes('@')) {
        number = number.split('@')[0];
        if(number.includes(':')){
          number = number.split(':')[1];
        }
      }
      this.addForCall.emit(number);
    }
  }

  getSipFromUri(value){
    const parts = value.split(':');
    if (parts.length > 1) {
      const subParts = parts[1].split('@');
      if (subParts.length > 1) {
        return subParts[0];
      }
    }
    return '';
  }

  getNumbertoCall(row){
    if(row.created_user == this.userId){
      return row.call_to;
    }else if(row.created_user != this.userId){
      return row.call_from;
    }else{
      return row.call_from;
    }
  }

  getName(row: any){
    if(row.created_user == this.userId){ // Outbound call
      if(row?.accepted_by_name?.trim())
        return row?.accepted_by_name.trim();
      else if(row?.call_to_name?.trim())
        return row?.call_to_name.trim();
      else if(row?.company_users_name?.trim())
        return row?.company_users_name.trim();
      else
        return 'Unknown'
    }else{ // Inbound or missed call
      if(row?.company_users_name?.trim())
        return row?.company_users_name?.trim();
      else if(row?.created_by?.trim())
        return row?.created_by.trim();
      else
        return 'Unknown'
    }
  }

  onAvatarMouseEnter(){
    this.initiateCallDirective.hide();
  }

  onAvatarMouseLeave(){
      this.initiateCallDirective.show();
  }

  getImage(row):string{
  
    if(row.direction == 'outbound' && this._phoneNumberPipe.transform(row.call_to) == this.currentSip && row.call_accepted_by != this.userId
       && row.call_from_image) //check it is a missed call
    {
      return this.fileUrl +row.call_from_image+'.jpg';
    }
    else if(row.call_accepted_by==this.userId && row.call_from_image) // check inbound call
    {
      return this.fileUrl +row.call_from_image+'.jpg';
    }
    else if(row.direction == 'outbound' && row.call_to_image)
    {
      return this.fileUrl +row.call_to_image+'.jpg';
    }
    else if(row.direction == 'inbound' && row.call_from_image)
    {
      return this.fileUrl +row.call_from_image+'.jpg';
    }
    else
    {
      return null;
    }

  }
  
  ngOnDestroy(): void {
      this.subs.unsubscribe()
  }

}
