<div class="flex justify-end">

    <div class="green-bg rounded-3xl call-info-container cursor-pointer" 
        [ngClass]="{
            'px-[2px]': this.activeCallerSession,
            'w-0': !this.activeCallerSession,
            'rounded-r-none': ((callcenterShared.holdCallQ.length > 0) || (callcenterShared.initialCallQ.length > 0))
        }">
    
            <div class="call-info-container" *ngIf="this.activeCallerSession" (click)="openCallCenter()">

            <div class="flex flex-nowrap items-center pl-0.5 pr-0.5">
              
                  <div class="h-8 w-8 rounded-full mr-1 p-[2px] flex justify-center items-center text-lg bg-white border-black">

                    @if(activeCallerSession?.callDetails?.company_users_image)
                    {
                        <img
                        class="w-full h-full rounded-full object-cover"
                        [src]="fileURL+activeCallerSession?.callDetails?.company_users_image"
                        alt="Contact avatar"/>
                    }
                    @else if(activeCallerSession?.callDetails?.call_to_image)
                    {
                        <img
                        class="w-full h-full rounded-full object-cover"
                        [src]="fileURL+activeCallerSession?.callDetails?.call_to_image"
                        alt="Contact avatar"/>
                    }
                    @else
                    {
        
                        @if(activeCallerSession?.customValues?.callType == 'inbound'){

                            @if(activeCallerSession?.customValues?.isAgentCall)
                            {
                                @if(isNullorEmpty(activeCallerSession?.callDetails?.agent_name)){
                                    UN
                                }
                                @else{
                                    {{activeCallerSession.callDetails?.agent_name | shortName}}
                                }
                            }
                            @if(!activeCallerSession?.customValues?.isAgentCall)
                            {
                                @if(isNullorEmpty(activeCallerSession.callDetails?.company_users_name)){
                                    UN
                                }
                                @else{
                                    {{activeCallerSession.callDetails?.company_users_name | shortName}}
                                }
                            } 
                        }
                        @else if(activeCallerSession?.customValues?.callType == 'outbound'){
                            @if(activeCallerSession?.customValues?.isAgentCall)
                            {
                                @if(isNullorEmpty(activeCallerSession.callDetails?.to_sip_user?.name)){
                                    UN
                                }
                                @else{
                                    {{activeCallerSession.callDetails?.to_sip_user?.name | shortName}}
                                }
                            }
                            @if(!activeCallerSession?.customValues?.isAgentCall)
                            {
                                @if(isNullorEmpty(activeCallerSession.callDetails?.users_name)){
                                    UN
                                }
                                @else{
                                    {{activeCallerSession.callDetails?.users_name | shortName}}
                                }
                            }
                           
                        }
                    }
        
       

                </div>
                <div class="ml-0.5 mr-2.5">

                   <p class="leading-4 font-medium text-white">

                     @if(activeCallerSession?.customValues?.isAgentCall)
                     {
                        @if (activeCallerSession.customValues.callType == 'inbound') {
                           {{isNullorEmpty(activeCallerSession?.callDetails?.agent_name)? 'Unknown': 
                           activeCallerSession?.callDetails?.agent_name+' ('+activeCallerSession?.callDetails?.agent_sub_org_name+')'}}
                           
                        }
                        @else {
                           {{isNullorEmpty(activeCallerSession.callDetails?.to_sip_user?.name)? 'Unknown': 
                          activeCallerSession.callDetails?.to_sip_user?.name+' ('+activeCallerSession?.callDetails?.to_sip_user?.suborg__name+')'}}
                        }
                     }
                     
                     @if(!activeCallerSession?.customValues?.isAgentCall)
                     
                     {
                        @if (activeCallerSession.customValues.callType == 'inbound') {
                           {{isNullorEmpty(activeCallerSession?.callDetails?.company_users_name)? 'Unknown': 
                           activeCallerSession?.callDetails?.company_users_name}}
                           
                        }
                        @else {
                           {{isNullorEmpty(activeCallerSession?.callDetails?.users_name)? 'Unknown': 
                           activeCallerSession?.callDetails?.users_name}} 
                        }
                     }
                     <span class="timer">   
                    <span [hidden]="!activeCallerSession?.customValues.elapsedTime" class="ml-1">{{formatTime(this.activeCallerSession?.customValues.elapsedTime) }}</span> </span></p>
                   <p class="text-[13px] font-medium text-white" *ngIf="activeCallerSession.customValues.callType == 'inbound'">
                     @if(activeCallerSession.customValues.isAgentCall){
                        {{activeCallerSession?.callDetails?.from_number.split('@')[0]}}
                     }
                     @else{
                        {{activeCallerSession?.callDetails?.from_number.length > 6 ? (activeCallerSession?.callDetails?.from_number | phoneNumber: false) : activeCallerSession?.callDetails?.from_number}}
                     }
                   </p>
                    <p class="text-[13px] font-medium text-white" *ngIf="activeCallerSession.customValues.callType == 'outbound'">{{!this.activeCallerSession?.customValues?.isAgentCall ? (this.activeCallerSession?.callDetails?.users_number | phoneNumber: false)
                     : this.activeCallerSession?.customValues?.callTo}}</p>
                </div>
            
                <div matRipple class="mx-1 h-7 w-7 rounded-full cursor-pointer bg-red-500 p-.5 shadow-lg call-end-active" >
                   <img src="../../../../../assets/icons/call-center/call-end.svg" class="cursor-pointer" alt="FAQ" (click)="endCall(activeCallerSession)">
                </div>
                <!-- [ngClass]="activeCallerSession.session?.state == 'Established' ? 'call-end-active' : 'call-end-disabled'" -->
            
                <div *ngIf="!activeCallerSession.customValues.isMuted" matRipple class="mr-0.5 h-7 w-7 rounded-full cursor-pointer bg-gray-700 p-1 shadow-lg" 
                [ngClass]="{'action-disable': activeCallerSession.session?.state != 'Established'}">
                   <img src="../../../../../assets/icons/call-center/mute-white.svg" class="bg-white-700 cursor-pointer" alt="FAQ" (click) = "muteCall()">
                </div>
                <div *ngIf="activeCallerSession.customValues.isMuted" matRipple class="mr-0.5 h-7 w-7 rounded-full cursor-pointer bg-gray-700 p-1 shadow-lg" 
                [ngClass]="{'mute-action': activeCallerSession.customValues.isMuted}" (click) = "muteCall()">
                  <img src="../../../../../assets/icons/call-center/mute.svg" class="bg-white-700 cursor-pointer" alt="FAQ">
               </div>
             
            </div>

        </div>
          
    </div>

    <!-- show callQ and hold call info -->
    <div class="select-none bg-[#D9EDE5] place-content-center" [ngClass]="this.activeCallerSession?'rounded-r-3xl':'rounded-3xl'"
        *ngIf="(callcenterShared.holdCallQ.length>0) || (callcenterShared.initialCallQ.length>0)">
        <div class="flex items-center px-1.5 h-[30px] gap-1 cursor-pointer hover:opacity-87" (click)="openCallCenter()">
            <div class="flex items-center justify-center bg-[#FF9900] rounded-[50px] px-[6px] py-1" *ngIf="callcenterShared.holdCallQ.length>0">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                    <rect x="0.661133" y="0.36084" width="5.17725" height="15.3328" rx="2" fill="white"/>
                    <rect x="9.00879" y="0.36084" width="5.17725" height="15.3328" rx="2" fill="white"/>
                </svg>
                <div class="flex justify-center bg-[#24223C] text-white font-bold rounded-full w-5 ml-1">{{callcenterShared.holdCallQ.length}}</div>
            </div>
            <div class="flex items-center justify-center bg-[#1EBE7B] rounded-[50px] pl-1 pr-[6px] py-1" *ngIf="callcenterShared.initialCallQ.length>0">
                <img src="assets\icons\call-center\incoming-call-header-icon.svg" alt="" class="w-5 h-5">
                <div class="flex justify-center bg-[#24223C] text-white font-bold rounded-full w-5 ml-1">{{callcenterShared.initialCallQ.length}}</div>
            </div>
        </div>
     </div>

    <div class="green-bg xl-shadow rounded-3xl p-[2px] cursor-pointer dialer-icon ml-3">
        <div class="px-1 py-1 flex cursor-pointer" (click)="openDialer()">
            <mat-icon aria-hidden="true" [svgIcon]="'dialpad'" 
            class="text-white"></mat-icon>
         </div>
    </div>
</div>

