 
<div>  
  <ng-select #outgoingNumberNgSelect
    class="custom-dropdown border-none focus:outline-none"  
    [items]="phone_numbers"
    bindLabel="did"
    bindValue="sid"
    [(ngModel)]="selectedItem"
    (change)="selectItem($event)"
    [searchable]="false"
    [clearable]="false"
    [compareWith]="compareObjects"
    (open)="getPhoneNumbers();onAfterOpen()"
    (close)="isDropdownOpen = false"
  >
    <ng-template ng-header-tmp>
      <!-- <div class="flex items-center justify-between p-2 pl-0">
        <div class="text-[22px] font-semibold">Outbound Number</div>
        <button 
          class="w-3 h-3 text-black-500  focus:outline-none" 
          (click)="closeDropdown(outgoingNumberNgSelect)"
          aria-label="Close"
        >
          <img src="../../../../../assets/icons/call-center/close.svg" class="w-full h-full">
        </button>
      </div> -->
      <div class="relative w-full">
        <div class="absolute inset-y-0 left-0 flex items-center pl-3">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
            <path d="M15.7304 14.629L13.3252 12.2704C14.4345 10.9516 15.0424 9.2923 15.0424 7.56489C15.0424 3.52897 11.7588 0.245361 7.72285 0.245361C3.68693 0.245361 0.40332 3.52897 0.40332 7.56489C0.40332 11.6008 3.68693 14.8844 7.72285 14.8844C9.238 14.8844 10.6819 14.4274 11.9163 13.5599L14.3543 15.9526C14.5361 16.1413 14.7805 16.2454 15.0424 16.2454C15.2903 16.2454 15.5255 16.1508 15.704 15.979C16.0834 15.614 16.0954 15.0087 15.7304 14.629ZM7.72285 2.1548C10.706 2.1548 13.1329 4.58171 13.1329 7.56489C13.1329 10.5481 10.706 12.975 7.72285 12.975C4.73967 12.975 2.31276 10.5481 2.31276 7.56489C2.31276 4.58171 4.73967 2.1548 7.72285 2.1548Z" fill="#384244"/>
          </svg>
        </div>
        <div class="absolute ml-1 left-8 top-1/2 transform -translate-y-1/2 h-[13px] w-px bg-[#384244]"></div>
        <input id="search-number-field"
          type="text"
          placeholder="Search Number"
          (input)="onSearchTermChange($event)" 
          class="block w-full pl-11 pr-4 py-2 text-[16px] font-light border border-gray-300 rounded-md focus:outline-none"
        />
      </div>
    </ng-template>

    <ng-template ng-option-tmp let-item="item" let-index="index">
      <div class="flex items-center border-b p-3" style="border-color: rgba(56, 66, 68, 0.10);" (click)="selectItem(item)" 
        [ngClass]="{'bg-[#1065B4] bg-opacity-10 rounded border-none': selectedItem.did == item.did}">
        <div class="mr-2 rounded-full flex justify-center items-center outbond-ico-xs" [ngClass]="selectedItem.did == item.did ? 'bg-[#0CB482]' : 'bg-[#E0E0E0]'">
          <img src="../../../../../assets/icons/call-center/call-outgoing-arrow.svg" />
        </div>
        <span class="text-[16px]" [ngClass]="selectedItem.did == item.did ? 'font-medium' : 'font-normal'">{{ item.did | phoneNumber: false}}</span>
      </div>
    </ng-template>

    <ng-template ng-label-tmp let-item="item">
      <div class="flex items-center">
        <div class="w-6 h-6 mr-2 rounded-full flex justify-center items-center outbond-ico"
          [ngClass]="selectedItem.did == item.did ? 'bg-[#0CB482]' : 'bg-[#E0E0E0]'">
          <img src="../../../../../assets/icons/call-center/call-outgoing-arrow.svg" class="w-[13px] h-[13px"/>
        </div>
        <span class="text-[18px]"> Outbound {{ item.did | phoneNumber: false}}</span>
        <span class="cursor-pointer">
          <img  src="../../../../assets/icons/arrow-down-sm.svg" class="w-3 ml-2 transition-transform duration-300"
          [ngClass]="isDropdownOpen ? 'rotate-180' : 'rotate-0'">
        </span>
      </div>
    </ng-template>

    <ng-template ng-notfound-tmp>
      <div *ngIf="!is_loaded" class="space-y-2">
        <ng-container *ngTemplateOutlet="skeletonTemplate"></ng-container>
      </div>  
    </ng-template>
    
  </ng-select>
</div>

<ng-template #skeletonTemplate>
  <div class="w-full mx-auto skeleton">
      <div class="w-full">
          @for (count of [0,1,2,3]; track count) {
              <div class="flex pt-1.5 justify-center">
                  <ngx-skeleton-loader class="w-3/4" count="1" appearance="line" [theme]="{'margin-bottom':'0px',background:'#e9e9e9',height:'16px'}"/>
              </div>
          }
      </div>
  </div>
</ng-template>
  