<div class="flex justify-between items-center">
    <outgoing-number-select></outgoing-number-select>
    <span class="ml-auto text-[16px] text-[#246AD4] font-medium cursor-pointer underline" (click)="openAddContact()">+ Contact</span>
</div>

<div class="div">
    <div class="dialler-input-div mt-[-10px]">
        <!-- <form (submit)="onCall()">
        <input type="text" class="form-control" name="outbound-number" #dialInput placeholder="Enter number to call" 
        (paste)="onInputChange()"   
        (keydown)="keyDown($event)"  
        (keyup)="keyUp($event)"
        (ngModelChange)="dialNumber=$event"
        [ngModel]="dialNumber | phoneNumber" [value]="dialNumber | phoneNumber"/>
        </form>  -->
    
        <form (submit)="onCall()">
            <input type="text" PhoneNumberFormat class="form-control phone-input" name="outbound-number" #dialInput placeholder="Enter number to call" 
            [(ngModel)]="dialNumber" (ngModelChange)="onDiailNumberChange()" autocomplete="off">
            </form> 
    
        <div class="dialler-input-back" *ngIf="dialNumber?.length>0">
            <img src="../../../../../assets/icons/call-center/close-cancel.svg"  (click)="delNumber()" />
        </div>
    </div>
    
    <div class="container  flex justify-center items-center pb-[10px]">
        <div class="grid grid-cols-3 gap-x-6 gap-y-2.5 mt-2 mb-2">
            <button class="key bg-white rounded-full shadow-md flex items-center justify-center" (click) = "onKeyPress('1')">1</button>
            <button class="key bg-white rounded-full shadow-md flex items-center flex-col justify-center" (click) = "onKeyPress('2')">
                    <span  class="num">2</span>
                    <span class="alphabet">ABC</span>
            </button>
            <button class="key bg-white rounded-full shadow-md flex items-center flex-col justify-center" (click) = "onKeyPress('3')">
                    <span class="num">3</span>
                    <span class="alphabet">DEF</span>
            </button>
            <button class="key bg-white rounded-full shadow-md flex items-center flex-col justify-center" (click) = "onKeyPress('4')">
                <span class="num">4</span>
                <span class="alphabet">GHI</span>
            </button>
            <button class="key bg-white rounded-full shadow-md flex items-center flex-col justify-center" (click) = "onKeyPress('5')">
                <span class="num">5</span>
                <span class="alphabet">JKL</span>
            </button>
            <button class="key bg-white rounded-full shadow-md flex items-center flex-col justify-center" (click) = "onKeyPress('6')">
                <span class="num">6</span>
                <span class="alphabet">MNO</span>
            </button>
            <button class="key bg-white rounded-full shadow-md flex items-center flex-col justify-center" (click) = "onKeyPress('7')">
                <span class="num">7</span>
                <span class="alphabet">PQRS</span>
            </button>
            <button class="key bg-white rounded-full shadow-md flex items-center flex-col justify-center" (click) = "onKeyPress('8')">
                <span class="num">8</span>
                <span class="alphabet">TUV</span>
            </button>
            <button class="key bg-white rounded-full shadow-md flex items-center flex-col justify-center" (click) = "onKeyPress('9')">
                <span class="num">9</span>
                <span class="alphabet">WXYZ</span>
            </button>
            <button class="key bg-white rounded-full shadow-md flex items-center justify-center" (click) = "onKeyPress('*')">
                <span class="w-[20px] h-[20px] mb-4">*</span>
            </button>
            <button class="key bg-white rounded-full shadow-md flex items-center flex-col justify-center relative" (click) = "onKeyPress('0')">
                <span class="num absolute">0</span>
                <span class="alphabet plus-sign absolute mt-[3rem]">+</span>
            </button>
            <button class="key bg-white rounded-full shadow-md flex items-center  justify-center" (click) = "onKeyPress('#')">
              #
            </button>
    
    
            <div class="mt-4"></div>
            <button class="rounded-full shadow-md flex items-center justify-center mt-2" (click)="onCall()"  
            [ngClass] = "this.callcenterShared.initiatedOutboundCall? 'call-disable' : 'key call'" [disabled] = "this.callcenterShared.initiatedOutboundCall">
                <img class="w-[43px] h-[43px]" 
                alt="Mute" src="../../../../../assets/icons/call-center/call-pick.svg">
            </button>
            <button class="key bg-white rounded-full shadow-md flex items-center justify-center" *ngIf="dialNumber?.length>0"  (click)="removeNumber()">
                <img class="w-[40px] h-[40px]" 
                alt="Mute" src="../../../../../assets/icons/call-center/back-space.svg">
            </button>
        </div>
    </div>
</div>
